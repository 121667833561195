import * as React from 'react';
import {TextField,Autocomplete,Typography} from '@mui/material';


export default function ComboBox(props) {
  return (
    <div>  
       {
      props.isLable?(
          <Typography variant="label"
          style={{fontSize: '14px'}} >
      {props.lableValue}
    </Typography>
      ):null
  }
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={props.myOptions}
      size='small'
      value={props.value}
      getOptionLabel={(option) => option}
      onChange={props.onChange}
      style={{width: '100%'}}
      renderInput={(params) => <TextField style={{ width: "100%" }} className='border-rad-none' size='small' {...params}  />}
    />
    </div>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
