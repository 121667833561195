import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CalcinedSection,error } from "../recoil/atom";
import { useRecoilState } from "recoil";

const GeometryText = (props) => {
  const [calcinedDet] = useRecoilState(CalcinedSection);
  const [stepperError] = useRecoilState(error);

  const { isLable, lableVarient, lableValue, name, disabled, isString, onChange } = props;
  const { sample_geometry } = calcinedDet;

  return (
    <React.Fragment>
      {isLable && (
        <Typography variant={lableVarient} style={{ fontSize: '14px', fontWeight: '400' }}>
          {lableValue}
        </Typography>
      )}
      <div style={{ display: "flex", marginBottom: '12px' }}>
        {["Length", "Width", "Height"].map((dimension) => (
          <Box
            key={dimension}
            component="form"
            sx={{
              '& > :not(style)': { m: 0.5 },
            }}
            noValidate
            autoComplete="off"
            style={{ marginLeft: dimension !== "Length" ? "5px" : "0", marginRight: "5px" }}
          >
            <Typography style={{ fontSize: '12px' }}>{dimension}</Typography>
            <TextField
              id={dimension.toLowerCase()}
              name={name}
              disabled={disabled || null}
              value={sample_geometry && sample_geometry[dimension.toLowerCase()] ? sample_geometry[dimension.toLowerCase()] : ""}
              type={isString ? "string" : "number"}
              onChange={onChange}
              error={stepperError && !sample_geometry[dimension.toLowerCase()] ? true : false}
              variant="outlined" size="small"
            />
          </Box>
        ))}
      </div>
    </React.Fragment>
  );
}


export default GeometryText;
