import Home from "../Home/Home";
import Visit from "../MainVisit/Visit";
import UserManagement from "../components/UserManagement";
import Login from "../LoginPage/Login";

const Routes = {
  mainRoutes: [
    {
      path: "/home",
      name: "home",
      component: Home,
    },
    {
      path: "/visit",
      name: "Visit",
      component: Visit,
    },
    {
      path: "/userManagement",
      name: "User Management",
      component: UserManagement,

    },

    {
      path: "/login",
      name: "Login",
      component: Login,

    }
  ]
}
export default Routes;