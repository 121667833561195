import React from "react";
import { TextField, Typography } from "@mui/material";

const TextBox = (props) => {
  return (
    <React.Fragment>
      <Typography style={{ fontSize: "20px" }}>{props.label}</Typography>
      <TextField
        sx={{
          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
            {
              WebkitAppearance: "none",
              margin: 0,
            },
          "input[type=number]": {
            MozAppearance: "textfield",
          },
        }}
        required={props.required ? props.required : null}
        style={{ width: "90%", height: 50 }}
        id={props.id}
        type={props.isString ? "string" : "number"}
        isNumber={props.isNumber ? "number" : "text"}
        value={props.value}
        // InputProps={{
        //   inputProps: {
        //     min:props.min, max:props.max,
        //   },
        // }}
        name={props.name}
        onChange={props.onChange}
        inputRef={props.inputRef}
        error={props.error ? props.error : false}
        helperText={props.helperText ? props.helperText : ""}
        disabled={props.disabled ? props.disabled : false}
      />
    </React.Fragment>
  );
};

export default TextBox;
