import React, { useEffect, useState } from "react";
import {
    Button,
    Grid
} from "@mui/material";
import configParam from "../Config";
import { ReactComponent as Edit } from "../asset/Edit.svg";
import DeletePopup from "./DeletePopup";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Tooltip from '@mui/material/Tooltip';
import { user, userDataType } from "../recoil/atom";
import { useRecoilState } from "recoil";
import useMediaQuery from '@mui/material/useMediaQuery';
import AddUserForm from './AddUserForm';
import SuccessMessage from "./SuccessMessage";
import axios from 'axios';


const ApiURL = configParam.API_URL;

const RightContainer = (props) => {
    const matches = useMediaQuery('(max-width:600px)');
    const [users, setUsers] = useState([]);
    const [, setUserType] = useRecoilState(userDataType);
    const [currentUsers, setCurrentUsers] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [type, setType] = useState("");
    const [, setStatus] = useState(false);
    const [show, setShow] = useState(false);
    const [deleteModule, setDeleteModule] = useState(false);
    const [rowIndex, setRowIndex] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const userDetails = localStorage.getItem("loginUserDetails");
    const loginUserDetails = JSON.parse(userDetails);

    const columns = [
        {
            field: "sno",
            headerName: "S.No",
            filterable: false,
            width: 200,
            // renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
        },
        {
            field: "name",
            headerName: "Name",
            width: 220,
            editable: false,
        },
        {
            field: "operator_id",
            headerName: "Operator Id",
            width: 220,
            editable: false,
        },
        {
            field: "role",
            headerName: "Role",
            width: 220,
            editable: false,
        },
        {
            field: "",
            headerName: "Actions",
            sortable: false,
            width: 180,
            disableClickEventBubbling: false,
            renderCell: (index, value) => {
                return (
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ cursor: "pointer" }}
                    >
                        <Tooltip title="Edit">
                            <Edit
                                id={index}
                                style={{ marginRight: "15px", cursor: "pointer" }}
                                onClick={() => {
                                    onHandleEdit(index);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteIcon onClick={() => handleDeleteRow(index)} style={{ cursor: "pointer" }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];
    useEffect(() => {
        getUserList();
    }, []);
    useEffect(() => {
        setTableData(users);
    }, [users]);

    const getUserList = () => {
        const url = ApiURL + "get_operator_list";
        axios.get(url, {
            headers: {
                authorization: loginUserDetails.web_token,
                //secret_key: loginUserDetails.web_secret
            }
        }).then((res) => {
            if (res.data.data.res.length > 0) {
                const newArr = res.data.data.res.map((z, index) => {
                    let obj = { ...z };
                    const name = z.name;
                    const operatorId = z.operator_id;
                    obj["name"] = name;
                    obj["operatorId"] = operatorId;
                    obj["role"] = z.role;
                    obj["sno"] = index + 1;
                    return obj;
                });
                setUsers(newArr);
            } else {
                setUsers([]);
            }
        });

    };
    const rowDelete = (index) => {
        const url = ApiURL + "delete_user"
        axios.post(url, {
            operator_id: index.id,
            headers: {
                authorization: loginUserDetails.web_token,
                //secret_key: loginUserDetails.web_secret
            }
        }).then((data) => {
            if (data.data.data.user > 0) {
                getUserList();
                handleClose();
                onHandleAlert();
            }
        });
    };
    const handleDeleteRow = (index) => {
        setType("Delete User")
        setDeleteModule(true);
        setRowIndex(index)
        getUserList();
    };
    const onHandleEdit = (index) => {
        setUserType(1)
        setType("Update User");
        setCurrentUsers(index.row);
        setOpen(true);
        setIsEditing(true);
    };
    const handleClose = () => {
        setOpen(false);
        getUserList();
        setDeleteModule(false);

    };
    const addNew = () => {
        setType("Add User");
        setUserType(0)
        setCurrentUsers([]);
        setOpen(true);
        setIsEditing(false)
    };

    const setStatusValue = (value) => {
        setStatus(value);
    };

    const onHandleClosed = () => {
        setShow(false);
        setDeleteModule(false);
    };

    const onHandleAlert = () => {
        setShow(true);
    };

    return (

        <div>
            <SuccessMessage open={show} onClose={onHandleClosed} msg={type === "Add User" ? "User Added Successfully" : type === "Update User" ? "User Updated Successfully" : type === "Delete User" ? "User Deleted Successfully" : ""} />
            <DeletePopup open={deleteModule} onClose={onHandleClosed} handleDelete={() => rowDelete(rowIndex)} />
            <AddUserForm
                open={open}
                handleClose={handleClose}
                type={type}
                data={currentUsers}
                setStatus={setStatusValue}
                onHandleAlert={onHandleAlert}
                isEditing={isEditing}
            // onClick={addNew}
            />
            <Grid container direction='column'>
                <Grid item style={{ marginRight: 250 }}>
                    <Button
                        style={{
                            marginBottom: "10px",
                            width: "auto",
                            float: "right",
                            backgroundColor: "#1976d2",
                            color: "#FFFFFF",
                        }}
                        onClick={addNew}
                    >
                        Add New User
                    </Button>
                </Grid>
                {(<AddUserForm
                    variant="contained"
                    style={{
                        marginBottom: "10px",
                        width: matches ? "100%" : null,
                        float: "right",
                        // marginLeft: "5%",
                        backgroundColor: "#FFC400",
                        color: "#1A1B1F",
                    }}
                >
                    Add New User
                </AddUserForm>)}
                {/* </Grid> */}

                <Grid item>
                    <Box sx={{ height: 453, width: "80%" }} >
                        <DataGrid
                            rows={tableData && tableData.length > 0 ? tableData : []}
                            columns={columns}
                            getRowId={(row) => row.operator_id}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    csvOptions: { disableToolbarButton: true },
                                    printOptions: { disableToolbarButton: true },
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 }
                                }
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15]}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            disableExportSelector={true}
                        />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default RightContainer;
