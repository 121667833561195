import React from "react";
import { FormControl, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';


const PsdDatePicker = (props) => {
  const today = new Date().toISOString().split("T")[0];
  return (
    <div>
      {
        props.isLable ? (
          <Typography variant={props.lableVarient} style={{fontSize: '14px', fontWeight: '400'}}>
            {props.lableValue}
          </Typography>
        ) : null
      }
      <FormControl style={{ width: "100%" }} >
        <TextField
        style={{ width: "100%" }}
          // id={label ? label.replace(/ /g, "-") : ("date-id-" + parseInt(Math.random() * 1000))}
          variant="outlined"
          type="date"
          size="small"
          name={props.name}
          error={props.error ? props.error : false}
          value={typeof props.value === "string" ? props.value : ""}
          onChange={props.onChange}
          inputProps={{
            max: today // Disable future dates
          }}
        />
      </FormControl>
    </div>
  )
};

export default PsdDatePicker;