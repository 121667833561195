import * as React from 'react';
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';

const HourMinites = (props) => {
  return (
    <Box
      component="form"
      style={{ width: "100%", marginBottom: '16px' }}
      noValidate
      autoComplete="off"
    >
      {props.isLable && (
        <Typography variant={props.lableVarient} style={{ fontSize: '14px', fontWeight: '400' }}>
          {props.lableValue}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3}>
          <TimePicker
            ampm={false}
            openTo="hours"
            views={['hours', 'minutes', 'seconds']}
            inputFormat="HH:mm:ss"
            mask="__:__:__"
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            renderInput={(params) => (
              <TextField 
                size='small' 
                {...params}  
                InputProps={{
                  endAdornment: null,
                }} 
                error={props.error}
              />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  );
};

export default HourMinites ;