import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DriedSection,error } from "../recoil/atom";
import { useRecoilState } from "recoil";

const DriedGeometry = (props) => {
 const [drieddDet] = useRecoilState(DriedSection);
 const [stepperError] = useRecoilState(error);
 return (
  <React.Fragment>
      {
        props.isLable ? (
          <Typography variant={props.lableVarient} style={{fontSize: '14px', fontWeight: '400'}}>
            {props.lableValue}
          </Typography>
        ) : null
      }
      <div style={{ display: "flex", marginBottom: '12px' }}>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 0.5 },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography style={{fontSize: '12px'}}>Length</Typography>
          <TextField id="length"
            name={props.name}
            disabled={props.disabled ? props.disabled : null}
            value={drieddDet.sample_geometry["length"] ? drieddDet.sample_geometry["length"]:"" }
            type={props.isString ? "string" : "number"}
            onChange={props.onChange}
            error={stepperError && !drieddDet.sample_geometry["length"] }
            variant="outlined" size="small" />
        </Box>

        <Box
          component="form"
          style={{marginLeft: "5px",marginRight:"5px"}}
          sx={{
            '& > :not(style)': { m: 0.5 },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography style={{fontSize: '12px'}}>Width</Typography>
          <TextField id="width"
            name={props.name}
            disabled={props.disabled ? props.disabled : null}
            value={drieddDet.sample_geometry&& drieddDet.sample_geometry["width"] ? drieddDet.sample_geometry["width"] : ""}
            type={props.isString ? "string" : "number"}
            onChange={props.onChange}
            error={stepperError && !drieddDet.sample_geometry["width"] }
            variant="outlined" size="small" />

        </Box>

        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 0.5 },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography style={{fontSize: '12px'}}>Height</Typography>
          <TextField id="height"
            name={props.name}
            disabled={props.disabled ? props.disabled : null}
            value={drieddDet.sample_geometry&& drieddDet.sample_geometry["height"] ? drieddDet.sample_geometry["height"]:""}
            type={props.isString ? "string" : "number"}
            onChange={props.onChange}
            error={stepperError && !drieddDet.sample_geometry["height"] }
            variant="outlined" size="small" />
        </Box>
      </div>
    </React.Fragment>
 );
}

export default DriedGeometry;
