import { selector } from "recoil";
import { selectedVisitObj, fieldChange } from "../../src/recoil/atom";

import moment from 'moment';

const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const getConvertedJSON = selector({
  key: "getConvertedJSON",
  get: ({ get }) => {
    let VisitData = [];
    let selectResData = deepCopy(get(selectedVisitObj));
    const fieldsampleType = get(fieldChange);

    const psdMeasurementDet = () => {
      if (selectResData[0].psd_ids.length > 0) {
        let psdFile = [];
        selectResData && selectResData[0].psd.map((items, index) => {
          psdFile[index] = {
            arrested_sample: items ? items.psd
            ["arrested_sample"] : "",
            arrested_time: items ? items.psd["arrested_time"] : "",
            psdDate: moment(items.psd["date"]).format('YYYY-MM-DD'),
            legend_name: items ? items.psd["legend_name"] : "",
            filename: (items) ? items.psd["psd_filename"] : "",
            psd_id: (items) ? items.psd["psd_id"] : "",
            refractive_index: (items) ? items.psd["refractive_index"] : "",
            d10: (items) ? items.psd["d10"] : "",
            d50: (items) ? items.psd["d50"] : "",
            d90: (items) ? items.psd["d90"] : "",
            visitStatus: "update"
          }
        })
        return psdFile;
      }
      else {
        return [];
      }
    }
    const imageDocDet = () => {
      if (selectResData[0].image_ids.length > 0) {
        let imgFile = [];
        selectResData[0].images.map((items, index) => {
          imgFile[index] = {
            imageDate: (items) ? moment(items.image["date"]).format('YYYY-MM-DD') : "",
            media_ids: (items) ? items.image["media_id"] : "",
            file: {
              name: (items) ? items.image["img_filename"] : "",
            },
            visitStatus: "update"
          }

        })
        return imgFile;
      }
      else {
        return []
      }
    }
    const ssaDocDet = () => {
      if (selectResData[0].ssa_ids.length > 0) {
        let ssaFile = [];
        selectResData[0].ssa.map((items, index) => {
          ssaFile[index] = {
            imageDate: (items) ? moment(items.ssa["date"]).format('YYYY-MM-DD') : "",
            surface_area: (items) ? items.ssa["surface_area"] : "",
            file: {
              name: (items) ? items.ssa["ssa_filename"] : "",
            },

            ssa_id: (items) ? items.ssa["ssa_id"] : "",
            visitStatus: "update"
          }
        })
        return ssaFile;

      } else {
        return []
      }
    }

    const rheDocDet = () => {
      if (selectResData[0].rheology_ids.length > 0) {
        let rheFile = [];
        selectResData[0].rheology_measurement.map((items, index) => {
          rheFile[index] = {
            rheologyDate: (items) ? moment(items.rheology["date"]).format('YYYY-MM-DD') : "",
            legend_id: (items) ? items.rheology["legend_id"] : "",
            rheometer_id: (items) ? items.rheology["rheometer_id"] : "",
            time_to_pour: (items) ? items.rheology["time_to_pour"] : "",
            time_to_start_measurement: (items) ? items.rheology["time_to_start_measurement"] : "",
            rheology_id: (items) ? items.rheology["rheology_id"] : "",
            filename: (items) ? items.rheology["rhe_filename"] : "",
            visitStatus: "update"
          }
        })
        return rheFile;
      }
      else {
        return []
      }
    }

    const slumpDocDet = () => {
      if (selectResData[0].slump_ids.length > 0) {
        let slumFile = [];
        selectResData[0].slump.map((items, index) => {
          slumFile[index] = {
            slumpDate: (items) ? moment(items.slump["date"]).format('YYYY-MM-DD') : "",
            direction_1_size: (items) ? items.slump["direction_1_size"] : "",
            direction_2_size: (items) ? items.slump["direction_2_size"] : "",
            fill_weight: (items) ? items.slump["fill_weight"] : "",
            lift_speed: (items) ? items.slump["lift_speed"] : "",
            time_to_lift: (items) ? items.slump["time_to_lift"] : "",
            time_to_pour: (items) ? items.slump["time_to_pour"] : "",
            slump_id: (items) ? items.slump["slump_id"] : "",
            visitStatus: "update"
          }

        })
        return slumFile;
      } else {
        return []
      }
    }

    const dscDocDet = () => {
      if (selectResData[0].dsc_ids.length > 0) {
        let dscFile = [];
        selectResData[0].dsc.map((items, index) => {
          dscFile[index] = {
            dscDate: (items) ? moment(items.dsc["date"]).format('YYYY-MM-DD') : "",
            alpha_Fraction: (items) ? items.dsc["alpha_fraction"] : "",
            beta_Fraction: (items) ? items.dsc["beta_fraction"] : "",
            gypsum_Fraction: (items) ? items.dsc["gypsum_fraction"] : "",
            dsc_id: (items) ? items.dsc["dsc_id"] : "",
            file: {
              name: (items) ? items.dsc["dsc_filename"] : "",
            },
            visitStatus: "update"

          }

        })
        return dscFile;
      } else {
        return []
      }
    }

    const mapCombinedFile = () => {
      let mappedArray = [];
      if ({ ...fieldsampleType === "Combined" }) {
        if (selectResData && selectResData[0].combined_result.combined) {
          selectResData && selectResData[0].combined_result.combined.map((x, index) => {
            mappedArray[index] = {
              parent_ids: x ? x.parent_ids : '',
              masses: x ? x.masses : '',
              combined_id: x ? x.combined_id : "",
            }
          });
          return mappedArray;
        }
        else {
          return null
        }
      }

    };

    if (selectResData.length > 0) {
      selectResData.map((items) => {
        let resData = JSON.parse(items.sample_type_data);
        let VisitDetails = {
          StaticFields: {
            sample_number: (items) ? items
            ["sample_number"] : '',
            entry_date: (items) ? moment(items["entry_date"]).format('YYYY-MM-DD') : '',
            parent_id: (items) ? items["parent_id"] : '',
            moisture_content: (items) ? items["moisture_content"] : '',
            oddity_flag: (items) ? items["oddity_flag"] : '',
            additional_operator_ids: (items) ? items["additional_operator_ids"] : '',
            addNotes: (items && items.notes && items.notes.length > 0 && items.notes[0].note && items.notes[0].note["note"]) ? items.notes[0].note["note"] : "",
            note_id: (items) ? items["note_id"] : '',

          },
          ...(fieldsampleType === "Calcined" ? {
            CalcinedSection: {
              time: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.time : '',
              power: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.power : '',
              raw_file: resData && resData.sample_type_data.data.raw_file ? resData.sample_type_data.data.raw_file : '',
              frequency: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.frequency : '',
              sample_mass: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.sample_mass : '',
              location: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.location : '',
              sample_packing: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.sample_packing : '',
              sample_temperature_measured: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.sample_temperature_measured : '',
              calcination_start_time: (items && resData && resData.sample_type_data.data.calcination_start_time) ? resData.sample_type_data.data.calcination_start_time.split("T")[0] : '',
              calci_time: (items && resData && resData.sample_type_data.data.calcination_start_time) ? resData.sample_type_data.data.calcination_start_time.split("T")[1] : '',
              sample_geometry: {
                length: (items && resData && resData.sample_type_data.data.sample_geometry && resData.sample_type_data.data.sample_geometry[0]) ? resData.sample_type_data.data.sample_geometry[0] : '',
                width: (items && resData && resData.sample_type_data.data.sample_geometry && resData.sample_type_data.data.sample_geometry[1]) ? resData.sample_type_data.data.sample_geometry[1] : '',
                height: (items && resData && resData.sample_type_data.data.sample_geometry && resData.sample_type_data.data.sample_geometry[2]) ? resData.sample_type_data.data.sample_geometry[2] : '',
              }
            },
          } : {}),

          ...(fieldsampleType === "Milled" ? {
            MilledSection: {
              starting_material_id: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.starting_material_id : '',
              media_mass: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.media_mass : '',
              sample_mass: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.sample_mass : '',
              rpm: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.rpm : '',
              duration: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.duration : "",
              media_id: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.media_id : '',
              jar_id: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.jar_id : '',
              media_Ids: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.media_id : '',
              milledTypes: (items) && resData && resData.sample_type_data ? resData.media_data ? `Material(${resData.media_data[0].data.material}) - Shape(${resData.media_data[0].data.shape})` : "others" : "others",
              millJarTypes: (items) && resData && resData.sample_type_data ? resData.jar_data ? `Material(${resData.jar_data[0].data.material}) - Type(${resData.jar_data[0].data.type}) - volume(${resData.jar_data[0].data.volume}) - diameter(${resData.jar_data[0].data.diameter})` : "others" : "others",
            },
            millMedia: {
              material: (items) ? resData.media_data ? resData.media_data[0].data.material : "others" : "others",
              shape: (items) ? resData.media_data ? resData.media_data[0].data.shape : "others" :"others"
            },
            millJarType: {
              material: (items) ? resData.jar_data ? resData.jar_data[0].data.material : "others" : "others",
              type: (items) ? resData.jar_data ? resData.jar_data[0].data.type : "others" : "others",
              volume: (items) ? resData.jar_data ? resData.jar_data[0].data.volume : "others" : "others",
              diameter: (items) ? resData.jar_data ? resData.jar_data[0].data.diameter : "others" : "others",
            }
          }
            : {}),

          ...(fieldsampleType === "Dried" ? {
            DriedSection: {
              time: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.time : '',
              oven_temperature: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.oven_temperature : '',
              relative_humidity: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.relative_humidity : '',
              initial_sample_weight: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.initial_sample_weight : '',
              sample_packing: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.sample_packing : '',
              final_sample_weight: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.final_sample_weight : '',
              raw_file: resData && resData.sample_type_data.data.file_name ? resData.sample_type_data.data.file_name : '',
              fan_speed: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.fan_speed : '',
              sample_geometry: {
                length: (items && resData.sample_type_data.data.sample_geometry && resData.sample_type_data.data.sample_geometry[0]) ? resData.sample_type_data.data.sample_geometry[0] : '',
                width: (items && resData.sample_type_data.data.sample_geometry && resData.sample_type_data.data.sample_geometry[1]) ? resData.sample_type_data.data.sample_geometry[1] : '',
                height: (items && resData.sample_type_data.data.sample_geometry && resData.sample_type_data.data.sample_geometry[2]) ? resData.sample_type_data.data.sample_geometry[2] : '',
              },
              sample_temperature_measured: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.sample_temperature_measured : '',

            }
          } : {}),

          ...(fieldsampleType === "Sieved" ? {
            SievedSection: {
              upper_bin_size: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.upper_bin_size : '',
              lower_bin_size: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.lower_bin_size : '',
              sieve_type: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.sieve_type : '',
              sieve_duration: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.sieve_duration : '',
              sieve_pressure: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.sieve_pressure : '',
              mass_before: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.mass_before : '',
              mass_retained: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.mass_retained : '',
              mass_through: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.mass_through : '',
            }
          } : {}),

          ...(fieldsampleType === "Formed Into Slurry" ? {
            FormedIntoSlurry: {
              water_gauge: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.water_gauge : '',
              retarder_amount: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data["retarder amount"] : '',
              power_setting: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.power_setting : '',
              duration_of_mixing: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.duration_of_mixing : "",
              rheology_modifier_amount: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.rheology_modifier_amount : '',
              additives: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.additives : '',
              solid_loading: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.solid_loading : '',
              retarder_id: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.retarder_id : '',
              mixing_equipment_id: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.mixing_equipment_id : '',
              rheology_modifier_id: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.rheology_modifier_id : '',
              formedTypes: (items) ? resData.rheology_modifier_data ? `Type (${resData.rheology_modifier_data[0].data.type})` : "others" : "others",
              mixedSluryTypes: (items) ? resData.mix_equipment_data ? `Type (${resData.mix_equipment_data[0].data.type})` : "others" : "others",
              retaterTypes: (items) ? resData.retarder_data ? `Type (${resData.retarder_data[0].data.type})` : "others" : "others",
            },
            formedSlurryType1: {
              type3: (items) ? resData.rheology_modifier_data ? resData.rheology_modifier_data[0].data.type : "others" : "others",


            },
            formedSlurryType2: {
              type2: (items) ? resData.mix_equipment_data ? resData.mix_equipment_data[0].data.type : "others" : "others",
            },
            formedSlurryType3: {
              type1: (items) ? resData.retarder_data ? resData.retarder_data[0].data.type : "others" : "others",
            }

          } : {}),

          ...(fieldsampleType === "Dry Aged" ? {
            dryAged: {
              aging_duration: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.aging_duration : ''
            }
          } : {}),
          ...(fieldsampleType === "New Arrival" ? {
            newSampleSection: {
              origin: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.origin : '',
              type: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.type : '',
              lot_number: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.lot_number : '',
              date_shipped: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.date_shipped : '',
              date_received: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.date_received : '',
              received_at: (items) && resData && resData.sample_type_data ? resData.sample_type_data.data.received_at : '',
            }
          } : {}),

          psdFilesData: {
            data: psdMeasurementDet(),
          },

          imageFilesData: {
            data: imageDocDet(),
          },
          rheologyFilesData: {
            data: rheDocDet(),
          },
          slumpFilesData: {
            data: slumpDocDet(),
          },
          ssaFilesData: {
            data: ssaDocDet(),
          },
          dscFilesData: {
            data: dscDocDet(),
          },
          combinedSection: {
            data: mapCombinedFile()
          }
        };
        VisitData.push(VisitDetails)
        return null
      })
    }
    return VisitData;
  },
});
