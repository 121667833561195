import React, { useEffect, useState } from "react";
import TextBox from "../input/TextBox";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PsdDatePicker from "../input/PsdDatePicker";
import { imageFilesData } from "../recoil/atom";
import { useRecoilState } from "recoil";
import configParam from "../Config";
import { ReactComponent as DeleteRow } from "../asset/deleteIcon.svg";
import { ReactComponent as Upload } from "../asset/fileUpload.svg";
import { ReactComponent as AddRow } from "../asset/AddRows.svg";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
const ApiURL = configParam.API_URL;
const deepCopy = configParam.DEEP_COPY;

const ImageModel = (props) => {
  const [popupMsg, setPopupMsg] = useState("");
  const [selectedImageFiles, setSelectedImageFiles] =
    useRecoilState(imageFilesData);
  const [errors, setErrors] = useState(false);
  const userDetails = localStorage.getItem("loginUserDetails");
  const loginUserDetails = JSON.parse(userDetails);

  const handleImageChange = (val, index, key) => {
    let tmpObj = deepCopy(selectedImageFiles);
    tmpObj[index][key] = val;
    setSelectedImageFiles(tmpObj);
  };
  const tmpObj1 = [...selectedImageFiles];
  const obj1 = {
    id: "",
    imageDate: "",
    file: {
      name: "",
      type: "",
      content: "",
      error: false,
      fileErr: false,
    },
  };
  useEffect(() => {}, [selectedImageFiles]);
  const addRow = (index) => {
    if (selectedImageFiles[index].imageDate === "") {
      setErrors(true);
    } else {
      tmpObj1.push(obj1);
      setSelectedImageFiles(tmpObj1);
      setErrors(false);
    }
  };

  const deleteRow = (index) => {
    let tmpObj = deepCopy(selectedImageFiles);
    tmpObj.splice(index, 1);
    setSelectedImageFiles(tmpObj);
  };

  function handleUpload(e, index) {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpeg, .png, .svg";
    input.style.display = "none";
    document.body.appendChild(input);
    input.addEventListener("change", function (e) {
      handleFileChange(e, index);
    });
    input.click();
  }

  const handleFileChange = (event, index) => {
    let tmpObj = deepCopy(selectedImageFiles);
    let uploadedFiles = event.target.files;
    let fileUpload = uploadedFiles[0];
    // Check if file size is less than 10MB
    if (fileUpload.size > 10 * 1024 * 1024) {
      setPopupMsg("File size must be less than 10MB.");
      setTimeout(() => {
        setPopupMsg("");
      }, 4000);
      return;
    }
    let tmpFileObj = { name: fileUpload.name, type: fileUpload.type };
    let selectedFileName = tmpFileObj.name;
    if (
      tmpFileObj.type === "image/jpeg" ||
      tmpFileObj.type === "image/jpg" ||
      tmpFileObj.type === "image/png"
    ) {
      tmpObj[index]["file"] = tmpFileObj;
      setSelectedImageFiles(tmpObj);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result.split("base64,")[1];
        uploadBase64Image(base64Image, selectedFileName);
      };
      reader.readAsDataURL(fileUpload);
    } else {
      setPopupMsg("Please Select JPEG or PNG Files");
      setTimeout(() => {
        setPopupMsg("");
      }, 3000);
    }
  };

  const downloadFile = (imageName) => {
    const cleanedImageName = imageName.replace(/\s/g, "");
    const urlName = ApiURL + "download/" + cleanedImageName;
    const link = document.createElement("a");
    link.href = urlName;
    link.download = imageName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Example usage:
  async function uploadBase64Image(base64Image, name, type) {
    const url = ApiURL + "upload";
    axios
      .post(url, {
        image: base64Image,
        imageName: name.toString().replace(/\s+/g, ""),
        fileType: type,
        sampleModal:"image",
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((response) => {
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  }

  if (selectedImageFiles.length === 0) {
    tmpObj1.push(obj1);
    setSelectedImageFiles(tmpObj1);
  }

  return (
    <React.Fragment>
      <Typography>Images Details</Typography>

      {selectedImageFiles.map((value, index) => {
        return (
          <Card
            sx={{
              minWidth: 275,
              boxShadow: "none",
              border: "0.5px solid rgb(224 224 224)",
            }}
            style={{ marginTop: 20 }}
          >
            <CardContent>
              <Grid container spacing={1} style={{ width: "100%" }}>
                <Grid item sm="6" xs="12">
                  <PsdDatePicker
                    isLable={true}
                    lableVarient="label"
                    lableValue="Date"
                    name="imageDate"
                    value={value.imageDate}
                    onChange={(e) =>
                      handleImageChange(e.target.value, index, "imageDate")
                    }
                    error={errors && !value.imageDate ? true : false}
                  />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox
                    isLable={true}
                    isString={true}
                    lableVarient="label"
                    lableValue="Attach Documents"
                    uploadImage={
                      <Upload onClick={(e) => handleUpload(e, index)} />
                    }
                    value={value.file ? value.file.name : ""}
                    disabled={true}
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Note: Only PNG or JPEG files are allowed
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs="12"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {value.file.name ? (
                    <div
                      style={{
                        marginTop: "10px",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DownloadIcon
                        onClick={() => downloadFile(value.file.name)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {selectedImageFiles.length - 1 === index && (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                        borderRadius: "4px",
                        height: "40px",
                        alignItems: "center",
                        alignSelf: "end",
                        backgroundColor: "#270659",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "10px",
                          fontSize: 14,
                          color: "#fff",
                        }}
                      >
                        Add Row
                      </div>
                      <AddRow fill="#fff" onClick={() => addRow(index)} />
                    </div>
                  )}

                  {selectedImageFiles.length !== 1 ? (
                    <Tooltip title="Delete Row" placement="top">
                      <div
                        style={{
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 20,
                        }}
                      >
                        <DeleteRow onClick={() => deleteRow(index)} />
                      </div>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}

      {popupMsg && (
        <Alert
          style={{ width: "80%" }}
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setPopupMsg("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {popupMsg}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default ImageModel;
