import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography,FormHelperText  } from '@mui/material';


export default function SampleOption(props) {
  return (
   <FormControl sx={{ minWidth: "100%", marginBottom:"16px"}} size="small" >
      {
        props.isLable ? (
          <Typography  variant="label"
          style={{fontSize: '14px'}}>
            {props.lableValue}
          </Typography>
        ) : null
      }
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={props.value}
        name={props.name}
        error={props.error}
        onChange={props.onChange}
        variant='outlined'
        disabled={props.disabled}
 

      >
        
        {props.options &&

props.options.map((d,index) => <MenuItem key={index} value={d}>{d}</MenuItem>) }

      </Select>
      {props.helperText && <FormHelperText sx={{ color: props.helperText ? 'red' : 'inherit' }}>{props.helperText }</FormHelperText>}
    </FormControl>
  
 );
}


