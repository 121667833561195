import React, { useState } from 'react';
import TextBox from '../input/TextBox';
import { Grid, Typography,Card,CardContent } from "@mui/material";
import PsdDatePicker from '../input/PsdDatePicker';
import { slumpFilesData } from "../recoil/atom";
import { useRecoilState } from "recoil";
import { ReactComponent as AddRow } from "../asset/AddRows.svg";
import { ReactComponent as DeleteRow } from "../asset/deleteIcon.svg";
import Tooltip from '@mui/material/Tooltip';
import configParam from "../Config";


const deepCopy = configParam.DEEP_COPY;

const Addslump = () => {
  const [slumpData, setSlumpData] = useRecoilState(slumpFilesData);
  const [errors, setErrors] = useState(false);


  const handleSlumpChange = (val, index, key) => {
    let tmpObj = deepCopy(slumpData);
    tmpObj[index][key] = val;
    setSlumpData(tmpObj)
  }

  const tmpObj1 = [...slumpData]
  const obj1 = {
    slumpDate: "",
    lift_speed: "",
    time_to_pour: "",
    time_to_lift: "",
    direction_1_size: "",
    direction_2_size: "",
    fill_weight: "",
    visitStatus: "",
  }

  const addRow = (index) => {
    if (slumpData[index].slumpDate === "" || slumpData[index].time_to_pour === "" || slumpData[index].time_to_start_measurement === "" || slumpData[index].legend_id === "" || slumpData[index].rheometer_id === "") {
      setErrors(true);

    }
    else {
      tmpObj1.push(obj1);
      tmpObj1.visitStatus = "new"
      setSlumpData(tmpObj1)
      setErrors(false)
    }
  }

  const deleteRow = (index) => {
    let tmpObj = deepCopy(slumpData);
    tmpObj.splice(index, 1);
    setSlumpData(tmpObj);
  }

  if (slumpData.length === 0) {
    tmpObj1.push(obj1);
    setSlumpData(tmpObj1)
  }

  return (
    <React.Fragment>
      <Typography >Slump Details</Typography>
      {
        slumpData.map((value,index) => {
          return (
            <Card  key={index} sx={{ minWidth: 275, boxShadow: 'none', border:"0.5px solid rgb(224 224 224)" }} style={{marginTop:20 }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item sm="6" xs="12">
                  <PsdDatePicker isLable={true} lableVarient="label" name="slumpDate" lableValue="Date (month,day,year)" value={value.slumpDate} onChange={(e) => handleSlumpChange(e.target.value, index, "slumpDate")} error={errors && !value.slumpDate}
 />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox isLable={true} lableVarient="label" lableValue="Lift speed (mm/s)" name="lift_speed" value={value.lift_speed} onChange={(e) => handleSlumpChange(e.target.value, index, "lift_speed")} error={errors && !value.lift_speed} />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox isLable={true} lableVarient="label" lableValue="Time to pour (seconds)" name="time_to_pour" value={value.time_to_pour} onChange={(e) => handleSlumpChange(e.target.value, index, "time_to_pour")} error={errors && !value.time_to_pour } />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox isLable={true} lableVarient="label" lableValue="Time to lift (seconds)" name="time_to_lift" value={value.time_to_lift} onChange={(e) => handleSlumpChange(e.target.value, index, "time_to_lift")} error={errors && !value.time_to_lift } />
                </Grid>
             <Grid item sm="6" xs="12">
                  <TextBox isLable={true} lableVarient="label" lableValue="Direction 1 size (inches)" name="direction_1_size" value={value.direction_1_size} onChange={(e) => handleSlumpChange(e.target.value, index, "direction_1_size")} error={errors && !value.direction_1_size } />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox isLable={true} lableVarient="label" lableValue="Direction 2 size (inches)" name="direction_2_size" value={value.direction_2_size} onChange={(e) => handleSlumpChange(e.target.value, index, "direction_2_size")} error={errors && !value.direction_2_size } />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox isLable={true} lableVarient="label" lableValue="Fill weight (grams)" name="fill_weight" value={value.fill_weight} onChange={(e) => handleSlumpChange(e.target.value, index, "fill_weight")} error={errors && !value.fill_weight } />
                </Grid>
                <Grid item sm="6" xs="12" style={{ display: "flex", justifyContent: 'end', alignItems:"center" }}>
                {
                  slumpData.length - 1 === index &&
                  <div
                  role="button"
                  tabIndex="0"
                  style={{
                    cursor: "pointer",
                    display: "inline-flex",
                    borderRadius: "4px",
                    height: "40px",
                    alignItems: "center",
                    backgroundColor: "#270659"
                  }}
                  onClick={() => addRow(index)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addRow(index);
                    }
                  }}
                >
                  <div style={{ marginLeft: "15px", fontSize: 14, color: "#fff" }}>Add Row</div>
                  <AddRow fill='#fff' />
                </div> 
                      
                }
                {
                  slumpData.length !== 1 ?
                    <Tooltip title="Delete Row" placement="top">
                      <div style={{ cursor: "pointer", marginLeft:20}}>
                        <DeleteRow onClick={() => deleteRow(index)} />
                      </div>
                    </Tooltip> : <></>

                }
                   </Grid>
              </Grid>
              </CardContent>
              </Card>
          )
        })
      }


    </React.Fragment>





  );
}

export default Addslump;
