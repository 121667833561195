import React, { useEffect, useState } from "react";
import {
  DialogContent,
  Dialog,
  Typography,
  DialogActions,
  Button,
  DialogTitle,
} from "@mui/material";
import TextBox from "./TextBox";
import configParam from "../Config";
import { user, userDataType } from "../recoil/atom";
import { useRecoilState } from "recoil";
import axios from "axios";
import SampleOption from "../input/SampleOption";
const ApiURL = configParam.API_URL;

export default function AddUserForm(props) {
  const [value, setValue] = useState({
    name: "",
    operatorId: "",
    role: "",
  });
  const [userType] = useRecoilState(userDataType);
  const [error, setError] = useState(false);
  const [, setdisableSubmit] = useState(false);
  const [stepperError, setStepperError] = useState(false);

  const userDetails = localStorage.getItem("loginUserDetails");
  const loginUserDetails = JSON.parse(userDetails);
  const userRoles = ["Admin", "User"];

  const handleValue = (e) => {
    const { name, value } = e.target;
    setValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const onHandleSave = () => {
    if (value.name === "" || value.operatorId === "" || value.role === "") {
      setError(true);
      return true;
    } else {
      setError(false);
    }
    setdisableSubmit(false);
    if (userType === 0) {
      const url = ApiURL + "insert_user";
      axios
        .post(url, {
          name: value.name,
          operator_id: value.operatorId,
          role: value.role,
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
        })
        .then((data) => {
          if (data.data.data.user[0]) {
            props.setStatus(true);
            props.handleClose();
            props.onHandleAlert();
          }
        });
    } else {
      const url = ApiURL + "update_user";
      axios
        .post(url, {
          name: value.name,
          operator_id: value.operatorId,
          role: value.role,
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret
          },
        })
        .then((data) => {
          if (data.data.data.user > 0) {
            props.setStatus(true);
            props.handleClose();
            props.onHandleAlert();
          }
        });
    }
  };

  useEffect(() => {
    if (props.open) {
      setError(false);
      setTimeout(function () {
        setFeildInitialValue();
        props.setStatus(false);
      }, 5);
    }
  }, [props.open]);

  const setFeildInitialValue = () => {
    let data = {
      name: "",
      operatorId: "",
      role: ""
    };
    data.name = props.data.name ? props.data.name : "";
    data.role = props.data.role ? props.data.role : "";
    data.operatorId = props.data.operatorId ? props.data.operatorId : "";
    setValue(data);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          {props.type}
        </DialogTitle>
        <DialogContent>
          <div></div>
          <div style={{ marginTop: 20 }}>
            <TextBox
              label="Name"
              name="name"
              isNumber={true}
              isString={true}
              value={value.name}
              onChange={handleValue}
            />
          </div>
          <div style={{ marginTop: 20}}>
            <TextBox
              label="Operator ID"
              name="operatorId"
              isNumber={true}
              value={value.operatorId}
              isString={true}
              disabled={props.isEditing}
              onChange={handleValue}
            />
          </div>
          <div style={{ marginTop: 20, fontSize: "20px"  }}>
            <SampleOption
              lableVarient="body2"
              name="role"
              isLable={true}
              lableValue="Role"
              value={value.role}
              onChange={handleValue}
              options={userRoles}
            />
          </div>

          {error ? (
            <Typography
              variant="subtitle2"
              color="red"
              style={{ marginTop: 8 }}
            >
              {" "}
              Please Enter All Fields
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onHandleSave}>
            Save
          </Button>
          <Button
            onClick={() => {
              setError(false);
              props.handleClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
