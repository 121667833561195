import React, { useState } from "react";
import TextBox from "../input/TextBox";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Alert,
  IconButton,
} from "@mui/material";
import PsdDatePicker from "../input/PsdDatePicker";
import CloseIcon from "@mui/icons-material/Close";
import { rheologyFilesData, user } from "../recoil/atom";
import { useRecoilState } from "recoil";
import { ReactComponent as Upload } from "../asset/fileUpload.svg";
import { ReactComponent as AddRow } from "../asset/AddRows.svg";
import configParam from "../Config";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import moment from "moment";
import { ReactComponent as DeleteRow } from "../asset/deleteIcon.svg";
import HourMinites from "../input/HourMinites";
import DownloadIcon from "@mui/icons-material/Download";

const deepCopy = configParam.DEEP_COPY;
const ApiURL = configParam.API_URL;

const Rheology = () => {
  const [popupMsg, setPopupMsg] = useState("");
  const acceptableFileName = ["xlsx", "xls"];
  const [rheologyData, setRheologyData] = useRecoilState(rheologyFilesData);
  const [errors, setErrors] = useState(false);
  const userDetails = localStorage.getItem("loginUserDetails");
  const loginUserDetails = JSON.parse(userDetails);

  const handleRhologyChange = (val, index, key) => {
    let tmpObj = deepCopy(rheologyData);
    if (val && val.$d) {
      tmpObj[index][key] =
        key === "time_to_pour" || key === "time_to_start_measurement"
          ? moment(val.$d).format("HH:mm:ss")
          : val;
    } else {
      tmpObj[index][key] = val;
    }
    setRheologyData(tmpObj);
  };

  const tmpObj1 = [...rheologyData];
  let obj1 = {
    rheologyDate: "",
    time_to_pour: "",
    time_to_start_measurement: "",
    legend_id: "",
    rheometer_id: "",
  };
  const addRow = (index) => {
    if (
      rheologyData[index].rheologyDate === "" ||
      rheologyData[index].arrested_sample === "" ||
      rheologyData[index].time_to_pour === "" ||
      rheologyData[index].time_to_start_measurement === "" ||
      rheologyData[index].rheometer_id === ""
    ) {
      setErrors(true);
    } else {
      tmpObj1.push(obj1);
      setRheologyData(tmpObj1);
      setErrors(false);
    }
  };

  const deleteRow = (index) => {
    let tmpObj = deepCopy(rheologyData);
    tmpObj.splice(index, 1);
    setRheologyData(tmpObj);
  };
  const checkFileName = (name) => {
    return acceptableFileName.includes(name.split(".").pop().toLowerCase());
  };

  const readDataFromExcel = async (data, index, MyFile) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64String = e.target.result.split(",")[1];
        const selectName = MyFile.name;
        await uploadBase64Excel(base64String, selectName);
        let tmpObj = deepCopy(rheologyData);
        tmpObj[index]["filename"] = MyFile.name;
        setRheologyData(tmpObj);
      };
      reader.readAsDataURL(MyFile);
    } catch (error) {
      console.error("Error reading data from Excel:", error);
      // Handle error as needed
    }
  };

  const handleUpload = (e, index) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xls, .xlsx";
    input.style.display = "none";
    document.body.appendChild(input);
    input.addEventListener("change", (event) => {
      handleFileChange(event, index);
    });

    input.click();
  };

  const handleFileChange = async (event, index) => {
    const MyFile = event.target.files[0];
    if (!MyFile) return;

    // Check if file size is less than 10MB
    if (MyFile.size > 10 * 1024 * 1024) {
      // 10MB in bytes
      setPopupMsg("File size must be less than 10MB.");
      setTimeout(() => {
        setPopupMsg("");
      }, 4000);
      return;
    }

    const selectName = MyFile.name;
    if (!checkFileName(selectName)) {
      setPopupMsg("Please Select Excel Files");
      setTimeout(() => {
        setPopupMsg("");
      }, 4000);
    } else {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        await readDataFromExcel(data, index, MyFile);
      };
      reader.readAsArrayBuffer(MyFile); // Read as ArrayBuffer
    }
  };

  async function uploadBase64Excel(changePdf64, name, type) {
    const url = ApiURL + "upload";
    axios
      .post(url, {
        image: changePdf64,
        imageName: name.toString().replace(/\s+/g, ""),
        fileType: type,
        sampleModal:"rheology",
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((response) => {
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  }

  const downloadFile = (imageName) => {
    const cleanedImageName = imageName.replace(/\s/g, "");
    const urlName = ApiURL + "download/" + cleanedImageName;
    const link = document.createElement("a");
    link.href = urlName;
    link.download = imageName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (rheologyData.length === 0) {
    tmpObj1.push(obj1);
    setRheologyData(tmpObj1);
  }
  let getTimes = new Date();
  const Strdateends = getTimes.toISOString().split("T").shift();
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Rheology Details
      </Typography>
      {rheologyData.map((value, index) => {
        return (
          <Card
            sx={{
              minWidth: 275,
              boxShadow: "none",
              border: "0.5px solid rgb(224 224 224)",
            }}
            style={{ marginTop: 20 }}
          >
            <CardContent>
              <Grid container spacing={1} style={{}}>
                <Grid item sm="6" xs="12">
                  <PsdDatePicker
                    isLable={true}
                    lableVarient="label"
                    lableValue="Date (month,day,year)"
                    value={value.rheologyDate}
                    onChange={(e) =>
                      handleRhologyChange(e.target.value, index, "rheologyDate")
                    }
                    error={errors && !value.rheologyDate}
                  />
                </Grid>
                <Grid item sm="6" xs="12">
                  <HourMinites
                    isLable={true}
                    lableVarient="label"
                    lableValue="Time to pour"
                    name="time_to_pour"
                    value={
                      value.time_to_pour
                        ? new Date(
                            moment(
                              Strdateends + " " + value.time_to_pour
                            ).format("YYYY-MM-DDTHH:mm:ss")
                          )
                        : ""
                    }
                    onChange={(e) =>
                      handleRhologyChange(e, index, "time_to_pour")
                    }
                    error={errors && !value.time_to_pour}
                  />
                </Grid>
                <Grid item sm="6" xs="12">
                  <HourMinites
                    isLable={true}
                    lableVarient="label"
                    lableValue="Time to start measurement"
                    name="time_to_start_measurement"
                    value={
                      value.time_to_start_measurement
                        ? new Date(
                            moment(
                              Strdateends +
                                " " +
                                value.time_to_start_measurement
                            ).format("YYYY-MM-DDTHH:mm:ss")
                          )
                        : ""
                    }
                    onChange={(e) =>
                      handleRhologyChange(e, index, "time_to_start_measurement")
                    }
                    error={errors && !value.time_to_start_measurement}
                  />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox
                    isLable={true}
                    isString={true}
                    lableVarient="label"
                    lableValue="Legend id"
                    name="legend_id"
                    value={value.legend_id}
                    onChange={(e) =>
                      handleRhologyChange(e.target.value, index, "legend_id")
                    }
                    error={errors && !value.legend_id}
                  />
                </Grid>

                <Grid item sm="6" xs="12">
                  <TextBox
                    isLable={true}
                    isString={true}
                    lableVarient="label"
                    lableValue="Rheometer ID"
                    name="rheometer_id"
                    value={value.rheometer_id}
                    onChange={(e) =>
                      handleRhologyChange(e.target.value, index, "rheometer_id")
                    }
                    error={errors && !value.rheometer_id}
                  />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox
                    isLable={true}
                    isString={true}
                    lableVarient="label"
                    lableValue="Attach Documents"
                    uploadImage={
                      <Upload onClick={(e) => handleUpload(e, index)} />
                    }
                    disabled={true}
                    value={value.filename || ""}
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Note: Only Excel files are allowed
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs="12"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {value.filename && (
                    <div
                      style={{
                        marginTop: "10px",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DownloadIcon
                        onClick={() => downloadFile(value.filename)}
                      />
                    </div>
                  )}
                  {rheologyData.length - 1 === index && (
                    <div
                      role="button"
                      tabIndex="0"
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                        borderRadius: "4px",
                        height: "40px",
                        alignItems: "center",
                        backgroundColor: "#270659",
                      }}
                      onClick={() => addRow(index)}
                      onKeyPress={(e) => {
                        e.key === "Enter" && addRow(index);
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "15px",
                          fontSize: 14,
                          color: "#fff",
                        }}
                      >
                        Add Row
                      </div>
                      <AddRow fill="#fff" />
                    </div>
                  )}

                  <Tooltip
                    title={rheologyData.length !== 1 && "Delete Row"}
                    placement="top"
                  >
                    <div style={{ cursor: "pointer", marginLeft: 20 }}>
                      {rheologyData.length !== 1 && (
                        <DeleteRow onClick={() => deleteRow(index)} />
                      )}
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}
      {popupMsg && (
        <Alert
          style={{ width: "87%", marginTop: "10px" }}
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setPopupMsg("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {popupMsg}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default Rheology;
