import React, { useState, useEffect } from "react";
import TextBox from "../input/TextBox";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as Upload } from "../asset/fileUpload.svg";
import { ReactComponent as AddRow } from "../asset/AddRows.svg";
import { dscFilesData } from "../recoil/atom";
import { useRecoilState } from "recoil";
import PsdDatePicker from "../input/PsdDatePicker";
import configParam from "../Config";
import { ReactComponent as DeleteRow } from "../asset/deleteIcon.svg";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
const ApiURL = configParam.API_URL;
const deepCopy = configParam.DEEP_COPY;
const DscModel = () => {
  const [dscData, setdscData] = useRecoilState(dscFilesData);
  const [popupMsg, setPopupMsg] = useState("");
  const [errors, setErrors] = useState(false);
  const userDetails = localStorage.getItem("loginUserDetails");
  const loginUserDetails = JSON.parse(userDetails);

  const handleDscChange = (val, index, key) => {
    let tmpObj = deepCopy(dscData);
    tmpObj[index][key] = val;
    setdscData(tmpObj);
  };

  let tmpObjArray = [...dscData];
  const obj1 = {
    dscDate: "",
    alpha_Fraction: "",
    beta_Fraction: "",
    gypsum_Fraction: "",
    file: {
      name: "",
      type: "",
      content: "",
      error: false,
      fileErr: false,
    },
  };
  const addRow = (index) => {
    let arr = [...tmpObjArray];
    arr.push(obj1);
    if (
      dscData[index].dscDate === "" ||
      dscData[index].alpha_Fraction === "" ||
      dscData[index].beta_Fraction === "" ||
      dscData[index].gypsum_Fraction === ""
    ) {
      setErrors(true);
    } else {
      setdscData(arr);
      setErrors(false);
    }
  };

  const deleteRow = (index) => {
    let tmpObj = deepCopy(dscData);
    tmpObj.splice(index, 1);
    setdscData(tmpObj);
  };

  useEffect(() => {}, [dscData]);

  function handleUpload(e, index) {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf";
    input.style.display = "none";
    document.body.appendChild(input);
    input.addEventListener("change", function (e) {
      handleFileChange(e, index);
    });
    input.click();
  }
  // setSsaData
  const handleFileChange = async (event, index) => {
    let tmpObj = deepCopy(dscData);
    let uploadedFiles = event.target.files;
    let fileUplodes = uploadedFiles[0];
    if (fileUplodes.size > 10 * 1024 * 1024) {
      setPopupMsg("File size must be less than 10MB.");
      setTimeout(() => {
        setPopupMsg("");
      }, 4000);
      return;
    }
    let tmpFileObj = { name: fileUplodes.name, type: fileUplodes.type };
    let selectedFileName = tmpFileObj.name;
    let selectedType = tmpFileObj.type;
    if (fileUplodes.type === "application/pdf") {
      const reader = new FileReader();
      reader.onload = () => {
        // The result property contains the Blob as a base64-encoded string
        const changePdf64 = reader.result;
        uploadBasePdf(changePdf64, selectedFileName, selectedType);
        // Now you can use the base64 string as needed, for example:
      };
      tmpObj[index]["file"] = tmpFileObj;
      setdscData(tmpObj);
      reader.readAsDataURL(fileUplodes);
    } else {
      setPopupMsg("Please Select Pdf Files");
      setTimeout(() => {
        setPopupMsg("");
      }, 4000);
    }
  };

  async function uploadBasePdf(changePdf64, name, type) {
    const url = ApiURL + "upload";
    axios
      .post(url, {
        image: changePdf64
          .toString()
          .replace("data:application/pdf;base64,", ""),
        imageName: name.toString().replace(/\s+/g, ""),
        fileType: type,
        sampleModal:"dsc",
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((response) => {
      })
      .catch((error) => {
        // Handle error response (if needed)
        console.error("Error uploading image:", error);
      });
  }
  const downloadFile = (imageName) => {
    const cleanedImageName = imageName.replace(/\s/g, "");
    const urlName = ApiURL + "download/" + cleanedImageName;
    const link = document.createElement("a");
    link.href = urlName;
    link.download = imageName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (dscData.length === 0) {
    setdscData([obj1]);
  }
  return (
    <React.Fragment>
      <Typography variant="body1">DSC Details</Typography>
      {dscData.map((value, index) => {
        return (
          <Card
            sx={{
              minWidth: 275,
              boxShadow: "none",
              border: "0.5px solid rgb(224 224 224)",
            }}
            style={{ marginTop: 20 }}
          >
            <CardContent>
              <Grid container spacing={1}>
                <Grid item sm="6" xs="12">
                  <PsdDatePicker
                    isLable={true}
                    lableVarient="label"
                    lableValue="Date (month,day,year)"
                    name="dscDate"
                    value={value.dscDate}
                    onChange={(e) =>
                      handleDscChange(e.target.value, index, "dscDate")
                    }
                    error={errors && !value.dscDate}
                  />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox
                    isLable={true}
                    lableVarient="label"
                    lableValue="Alpha Fraction"
                    name="alpha_Fraction"
                    value={value.alpha_Fraction}
                    onChange={(e) =>
                      handleDscChange(e.target.value, index, "alpha_Fraction")
                    }
                    error={errors && !value.alpha_Fraction ? true : false}
                  />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox
                    isLable={true}
                    lableVarient="label"
                    lableValue="Beta Fraction"
                    name="beta_Fraction"
                    value={value.beta_Fraction}
                    onChange={(e) =>
                      handleDscChange(e.target.value, index, "beta_Fraction")
                    }
                    error={errors && !value.beta_Fraction ? true : false}
                  />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox
                    isLable={true}
                    lableVarient="label"
                    lableValue="Gypsum Fraction"
                    name="gypsum_Fraction"
                    value={value.gypsum_Fraction}
                    onChange={(e) =>
                      handleDscChange(e.target.value, index, "gypsum_Fraction")
                    }
                    error={errors && !value.gypsum_Fraction ? true : false}
                  />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox
                    isLable={true}
                    isString={true}
                    lableVarient="label"
                    lableValue="Attach Documents"
                    uploadImage={
                      <Upload onClick={(e) => handleUpload(e, index)} />
                    }
                    disabled={true}
                    value={value.file ? value.file.name : ""}
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Note: Only PDF files are allowed
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm="6"
                  xs="12"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {value.file.name ? (
                    <div style={{ cursor: "pointer" }}>
                      <DownloadIcon
                        onClick={() => downloadFile(value.file.name)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {dscData.length - 1 === index && (
                    <div
                      role="button"
                      tabIndex="0"
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                        borderRadius: "4px",
                        height: "40px",
                        alignItems: "center",
                        backgroundColor: "#270659",
                      }}
                      onClick={() => addRow(index)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          addRow(index);
                        }
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "15px",
                          fontSize: 14,
                          color: "#fff",
                        }}
                      >
                        Add Row
                      </div>
                      <AddRow fill="#fff" />
                    </div>
                  )}
                  {dscData.length !== 1 ? (
                    <Tooltip title="Delete Row" placement="top">
                      <div style={{ cursor: "pointer", marginLeft: 20 }}>
                        <DeleteRow onClick={() => deleteRow(index)} />
                      </div>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}
      {popupMsg && (
        <Alert
          style={{ width: "100%" }}
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setPopupMsg("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {popupMsg}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default DscModel;
