import React, { Suspense } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { CssBaseline, LinearProgress } from "@mui/material";
import Login from "./LoginPage/Login";
import routes from "../src/Router/MainRouters";
import "./App.css";
import { useRecoilState } from "recoil";
import { user } from "./recoil/atom";
import { AuthContext } from "./Context";
import RouteMissing from "./Router/RouteMissing";

export const switchRoutes = (
  <React.Fragment>
    {routes.mainRoutes.map((route, index) => {
      return route.component ? (
        <Route
          path={route.path}
          name={route.name}
          element={<route.component />}
          key={index}
        />
      ) : (
        <React.Fragment></React.Fragment>
      );
    })}

    <Route path="/" element={<Navigate replace to="/login" />} />
  </React.Fragment>
);

function App() {
  const currentUser = localStorage.getItem("loginUserDetails");
  const userData = JSON.parse(JSON.stringify(currentUser));
  const [userId, setUser] = useRecoilState(user);
  if (window.location.pathname !== "/login" && !userData) {
    window.location.pathname = "/login";
  }

  return (
    <div className="App">
      <AuthContext.Provider value={{ userId, setAuthTokens: userId }}>
        <CssBaseline />
        <BrowserRouter>
          <Suspense fallback={<LinearProgress />}>
            <Routes>
              {switchRoutes}
              <Route path="/login" element={<Login />}>
                <Route path="*" element={<RouteMissing />} />
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
