import { atom } from "recoil";
import dayjs from 'dayjs';

export const apiTimeout = atom({
  key: "apiTimeout",
  default: false
});

export const selectSampleDisable = atom({
  key: "selectSampleDisable",
  default: false
});

export const user = atom({
  key: "user",
  default: {
    sgid: "", name: "", operator_id: "", role: "", web_token: "", web_secret: ""
  },
});

export const userDataType = atom({
  key: "userDataType",
  default: 0,
});

export const sampleInfo = atom({
  key: "sampleInfo",
  default: {
    addComment: "",
    option: ""
  }
});

export const selectedVisitObj = atom({
  key: "selectedVisitObj",
  default: {},
});
export const StaticFields = atom({
  key: "StaticFields",
  default: {
    sample_number: Math.floor(Date.now()).toString().substring(5).replace(/0/, '1'),
    entry_date: dayjs(Date.now()),
    parent_id: "",
    oddity_flag: "",
    moisture_content: "",
    note_id: "",
    image_ids: "",
    primary_operator_id: "",
    additional_operator_ids: "",
    psd_ids: "",
    rheology_ids: "",
    slump_ids: "",
    ssa_ids: "",
    dsc_ids: "",
    operation_id: "",
    addNotes: ""
  }
});

export const CalcinedSection = atom({
  key: "CalcinedSection",
  default: {
    sample_number: "",
    time: "",
    power: "",
    frequency: "",
    sample_mass: "",
    location: "",
    sample_geometry: {
      length: "",
      width: "",
      height: ""
    },
    sample_packing: "",
    sample_temperature_measured: "",
    calcination_start_time: new Date(),
    calci_time: "",
    raw_file: ""

  }
});

export const uploadFile = atom({
  key: "uploadFile",
  default: null
});

export const uploadDriedFile = atom({
  key: "uploadDriedFile",
  default: null
});

export const MilledSection = atom({
  key: "MilledSection",
  default: {
    // sample_number: "",
    starting_material_id: "",
    media_mass: "",
    media_Ids: "",
    sample_mass: "",
    jar_id: "",
    rpm: "",
    sample_packing: "",
    duration: "",
    milledTypes: "others",
    millJarTypes: "others"
  }
});

export const millMedia = atom({
  key: "millMedia",
  default: {
    material: "",
    shape: "",
    media_id: ""
  }
});

export const millJarType = atom({
  key: "millJarType",
  default: {
    material: "",
    type: "",
    volume: "",
    diameter: "",
    jar_id: ""
  }
});

export const DriedSection = atom({
  key: "DriedSection",
  default: {
    // sample_number: "",
    time: "",
    fan_speed: "",
    oven_temperature: "",
    relative_humidity: "",
    initial_sample_weight: "",
    sample_geometry: {
      length: "",
      width: "",
      height: ""
    },
    sample_packing: "",
    final_sample_weight: "",
    sample_temperature_measured: "",
    raw_file: "",
    addNotes: ""
  }
});

export const SievedSection = atom({
  key: "SievedSection",
  default: {
    sample_number: "",
    upper_bin_size: "",
    lower_bin_size: "",
    sieve_type: "",
    sieve_duration: "",
    sieve_pressure: "",
    mass_before: "",
    mass_retained: "",
    mass_through: "",
    addNotes: ""

  }
});

export const FormedIntoSlurry = atom({
  key: "FormedIntoSlurry",
  default: {
    sample_number: "",
    water_gauge: "",
    retarder_id: "",
    retarder_amount: "",
    mixing_equipment_id: "",
    power_setting: "",
    duration_of_mixing: "",
    rheology_modifier_id: "",
    rheology_modifier_amount: "",
    additives: "",
    solid_loading: "",
    formedTypes: "others",
    mixedSluryTypes: "others",
    retaterTypes: "others",
    addNotes: ""

  }
});

export const formedSlurryType1 = atom({
  key: "formedSlurryType1",
  default: {
    type1: "",
    retarder_id: ""
  }
});

export const formedSlurryType2 = atom({
  key: "formedSlurryType2",
  default: {
    type2: "",
    mixing_equipment_id: ""
  }
});
export const formedSlurryType3 = atom({
  key: "formedSlurryType3",
  default: {
    type3: "",
    rheology_modifier_id: ""
  }
});

export const dryAged = atom({
  key: "DryAged",
  default: {
    sample_number: "",
    aging_duration: "",
    addNotes: ""
  }
});
export const sampleGeo = atom({
  key: "sampleGeo",
  default: "CalcinedSection"
});



export const combainedSec = () => {
  let rltIp = [{
    parent_ids: "",
    masses: "",

  }]
  return rltIp

};
export const combinedSection = atom({
  key: "combinedSection",
  default: combainedSec()
});




export const newSampleSection = atom({
  key: "newSampleSection",
  default: {
    sample_number: "",
    origin: "",
    type: "",
    lot_number: "",
    date_shipped:"",
    date_received: "",
    received_at: "",
    addNotes: ""
  }
});


export const media = atom({
  key: "media",
  default: {
    sampleNumberMedia: "",
    mediaID: "",
    operatorIDMedia: "",
    date: ""
  }
});

export const operators = atom({
  key: "operators",
  default: {
    sampleID: "",
    operatorID: "",
    name: ""
  }
});

export const psdMeasurement = atom({
  key: "psdMeasurement",
  default: {
    sampleNumberPSD: "",
    datePSD: "",
    legendName: "",
    operatorIDPSD: "",
    refractiveIndex: "",
    arrestedTime: "",
    arrestedSample: "",
    d10: "",
    d50: "",
    d90: "",
    diameterCumMM: "",
    diameterCumMicroMeter: "",
    file: {
      name: "",
      type: "",
      content: "",
      error: false
    }
  }
});

export const rheologyMeasurement = atom({
  key: "rheologyMeasurement",
  default: {
    rheologyID: "",
    sampleNumberRheology: "",
    dateRheology: "",
    operatorIDRheology: "",
    tabName: "",
    timeToPour: "",
    timeToStartMeas: "",
    legendIDRheology: "",
    rheometerID: "",
    filename: "",
    flowSweepData: ""
  }
});

export const slumpMeasurement = atom({
  key: "slumpMeasurement",
  default: {
    sampleNumberSlump: "",
    slumpID: "",
    operatorIDSlump: "",
    dateSlump: "",
    liftSpeed: "",
    timeToPourSlump: "",
    timeToLift: "",
    direction1Size: "",
    direction2Size: "",
    fillWeight: "",
  }
});




export const notes = atom({
  key: "notes",
  default: {
    noteID: "",
    note: "",
  }
});

export const imageFile = atom({
  key: "imageFile",
  default: {
    imageDate: ""
  }
});


// Psd Doc Atom
export const psdDocument = () => {
  let rltIp = [{
    legend_name: "",
    psdDate: "",
    arrested_time: "",
    arrested_sample: "",
    refractive_index: "",
    d10: "",
    d50: "",
    d90: "",
    diameter_q_undersize: "",
    filename: "",
    filecontent: "",
    visitStatus: "",
  }]
  return rltIp

};

export const psdFilesData = atom({
  key: "psdFilesData",
  default: psdDocument()
});



// Image Documents
export const imageDocument = () => {
  let imageIp = [{
    id: "",
    visitStatus: "",
    imageDate: "",
    file: {
      name: "",
      type: "",
      content: "",
      error: false,
      fileErr: false,

    }
  }]
  return imageIp;
};

export const imageFilesData = atom({
  key: "imageFilesData",
  default: imageDocument()
});

// SSA documents
export const ssaDocument = () => {
  let ssaIp = [{
    id: "",
    imageDate: "",
    surface_area: "",
    visitStatus: "",
    file: {
      name: "",
      type: "",
      content: "",
      error: false,
      fileErr: false,

    }
  }]
  return ssaIp;
};

export const ssaFilesData = atom({
  key: "ssaFilesData",
  default: ssaDocument()
});

// SSA documents
export const DscDocument = () => {
  let dscIp = [{
    dscDate: "",
    alpha_Fraction: "",
    beta_Fraction: "",
    gypsum_Fraction: "",
    visitStatus: "",
    file: {
      name: "",
      type: "",
      content: "",
      error: false,
      fileErr: false,
    }
  }]
  return dscIp;
};

export const dscFilesData = atom({
  key: "dscFilesData",
  default: DscDocument()
});

export const rheologyFile = () => {
  let rheologyIp = [{
    rheologyDate: "",
    time_to_pour: "",
    time_to_start_measurement: "",
    legend_id: "",
    rheometer_id: "",
    filename: "",
    visitStatus: "",
    fileType: ""
  }]
  return rheologyIp
}

export const rheologyFilesData = atom({
  key: "rheologyFilesData",
  default: rheologyFile()
});


export const slumpDocument = () => {
  let slumpIp = [{
    slumpDate: "",
    lift_speed: "",
    time_to_pour: "",
    time_to_lift: "",
    direction_1_size: "",
    direction_2_size: "",
    fill_weight: "",
    visitStatus: "",
  }]
  return slumpIp;
};

export const slumpFilesData = atom({
  key: "slumpFilesData",
  default: slumpDocument()
});


export const error = atom({
  key: "error",
  default: false
});

export const viewVisitData = atom({
  key: "viewVisitData",
  default: []
});


export const viewChange = atom({
  key: "viewChange",
  default: false
});

export const fieldChange = atom({
  key: "fieldChange",
  default: 'Calcined'
});

export const popupShow = atom({
  key: "popupShow",
  default: false
});
export const trialStatus = atom({
  key: "trialStatus",
  default: 0,
});

export const tabActive = atom({
  key: "tabActive",
  default: 0,
});
