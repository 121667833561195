import React, { useState, useEffect } from 'react';
import TextBox from '../input/TextBox';
import { Grid, Typography, Card, CardContent, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as Upload } from '../asset/fileUpload.svg';
import { ReactComponent as AddRow } from "../asset/AddRows.svg";
import { psdFilesData, user } from "../recoil/atom";
import { useRecoilState } from "recoil";
import Select from "../input/Select";
import configParam from "../Config";
import { ReactComponent as DeleteRow } from "../asset/deleteIcon.svg";
import Tooltip from '@mui/material/Tooltip';
import PsdDatePicker from '../input/PsdDatePicker';
import HourMinites from '../input/HourMinites';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import axios from 'axios';
import { Co2Sharp } from '@mui/icons-material';
const ApiURL = configParam.API_URL;

const deepCopy = configParam.DEEP_COPY;

const PsdModel = () => {
  const [popupMsg, setPopupMsg] = useState("");
  const [psdData, setPsdData] = useRecoilState(psdFilesData);
  const [errors, setErrors] = useState(false);
  const userDetails = localStorage.getItem("loginUserDetails");
  const loginUserDetails = JSON.parse(userDetails);
  const flags = ["true", "false"]
  const [errorMessage, setErrorMessage] = useState('');

  const handlePsdChange = (val, index, key) => {
    let tmpObj = deepCopy(psdData);
    setErrorMessage(''); // Clear previous error messages

    if (val && val.$d) {
      const formattedTime = moment(val.$d).format('HH:mm:ss');

      // Validate the time format
      const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;

      if (timeRegex.test(formattedTime)) {
        tmpObj[index][key] = key === "arrested_time" ? formattedTime : val;
      } else {
        setErrorMessage('Please enter a valid time in HH:mm:ss format.');
      }
    } else {
      tmpObj[index][key] = val;
    }
    setPsdData(tmpObj);
  };

  
  

  const tmpObj1 = [...psdData]
  const obj1 = {
    id: "",
    legend_name: "",
    psdDate: "",
    arrested_time: "",
    arrested_sample: "",
    refractive_index: "",
    d10: "",
    d50: "",
    d90: "",
    diameter_q_undersize: "",
    filename: "",
    visitStatus: "",
  };
  const addRow = (index) => {
    if (psdData[index].psdDate === "" || psdData[index].arrested_sample === "" || psdData[index].legend_name === "" || psdData[index].arrested_time === "") {
      setErrors(true);
    }
    else {
      tmpObj1.push(obj1);
      tmpObj1.visitStatus = "new"
      setPsdData(tmpObj1)
      setErrors(false)
    }
  }

  const deleteRow = (index) => {
    let tmpObj = deepCopy(psdData);
    tmpObj.splice(index, 1);
    setPsdData(tmpObj);
  }

  useEffect(() => {
  }, [psdData])

  function handleUpload(e, index) {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".txt"
    input.style.display = "none";
    document.body.appendChild(input);
    input.addEventListener("change", function (e) { handleFileChange(e, index); });
    input.click();
  }


  const readDataFromTxt = (data, MyFile, index) => {
    const reader = new FileReader();
    let tmpObj = deepCopy(psdData);
  
    reader.onload = async (e) => {
      const text = e.target.result;
  
      // Validation: Check for the presence of specific key phrases
      const requiredPhrases = [
        "Median size", "Mean size", "Variance", "St. Dev.", "Mode size", "Span",
        "Geo. mean size", "Geo. variance", "Diameter on cumulative %",
        "D(v,0.1)", "D(v,0.5)", "D(v,0.9)", "Refractive index (R)"
      ];
      const isValidFile = requiredPhrases.every(phrase => text.includes(phrase));
  
      if (!isValidFile) {
        setPopupMsg("File Doesn't Match");
        setTimeout(() => {
            setPopupMsg("");
        }, 4000);
        return;
      }
  
      tmpObj[index]["diameter_q_undersize"] = text.toString();
      tmpObj[index]["filename"] = MyFile.name;
  
      // Allowed keys to be processed
      const allowedKeys = ["D(v,0.1)", "D(v,0.5)", "D(v,0.9)", "Refractive index (R)"];
  
      text.split('\n\n').map(entry => {
        const obj = {};
        entry.split('\n').forEach(keyValue => {
          // Check if the key is allowed to be processed
          if (allowedKeys.some(allowedKey => keyValue.includes(allowedKey))) {
            if (keyValue.includes("D(v,0.1)")) {
              tmpObj[index]["d10"] = keyValue.split("             	 ")[1].split(" (")[0];
            } else if (keyValue.includes("D(v,0.5)")) {
              tmpObj[index]["d50"] = keyValue.split("             	 ")[1].split(" (")[0];
            } else if (keyValue.includes("D(v,0.9)")) {
              tmpObj[index]["d90"] = keyValue.split("             	")[1].split(" (")[0];
            } else if (keyValue.includes("Refractive index (R)")) {
              tmpObj[index]["refractive_index"] = keyValue.split("            	")[1].trim();
              setPsdData(tmpObj);
            }
          }
        });
        return obj;
      });
    };
  
    reader.readAsText(MyFile);
  };
  



  const handleFileChange = async (event, index) => {
    let uploadedFiles = event.target.files;
    let fileUplodes = uploadedFiles[0];
    const file = uploadedFiles[0];
    // Check if file size is less than 10MB
    if (file.size > 10 * 1024 * 1024) {
        setPopupMsg("File size must be less than 10MB.");
        setTimeout(() => {
            setPopupMsg("");
        }, 4000);
        return;
    }
    let tmpFileObj = { name: file.name, type: file.type };
    const selectedFileName = tmpFileObj.name;
    let reader = new FileReader();
    reader.onload = function () {
        let base64Blob = reader.result.split("base64,")[1];
        uploadBase64Text(base64Blob, selectedFileName);
    };

    if (fileUplodes.type === "text/plain") {
        reader.readAsDataURL(file);
        const data = await file.arrayBuffer();
        readDataFromTxt(data, file, index);
    } else {
        setPopupMsg("Please Select Text Files (4.1.22 Mill Experiment (45MIN).txt)");
        setTimeout(() => {
            setPopupMsg("");
        }, 4000);
    }
};

  async function uploadBase64Text(changePdf64, name, type) {
    const url = ApiURL + "upload"
    axios.post(url, {
      image: changePdf64.toString().replace("data:application/pdf;base64,", ""), 
      imageName: name.toString().replace(/' '/g, ''), 
      fileType: type,
      sampleModal:"psd",
       headers: {
        authorization: loginUserDetails.web_token,
      }
    })
      .then((response) => {
      })
      .catch((error) => {
        console.error('Error uploading Text File:', error);
      });
  }

  const downloadFile = (imageName) => {
    const urlName = ApiURL + 'download/' + imageName;
    const link = document.createElement('a');
    link.href = urlName;
    link.download = imageName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  if (psdData.length === 0) {
    tmpObj1.push(obj1);
    setPsdData(tmpObj1)
  }
  let getTime = new Date();
  const Strdateend = getTime.toISOString().split('T').shift();
  return (
    <React.Fragment>
      <Typography>Psd Details</Typography>
      {
        psdData.map((value, index) => {
          return (
            <Card sx={{ minWidth: 275, boxShadow: 'none', border: "0.5px solid rgb(224 224 224)" }} style={{ marginTop: 20 }} >
              <CardContent>
                <Grid container spacing={1} style={{ justifyContent: "space-between" }}>
                  <Grid item sm="6" xs="12">
                    <PsdDatePicker isLable={true} name="psdDate" value={value.psdDate} lableVarient="label" lableValue="Date" onChange={(e) => handlePsdChange(e.target.value, index, "psdDate")} error={errors && !value.psdDate ? true : false}
                    />
                  </Grid>
                  <Grid item sm="6" xs="12">
                    <TextBox isLable={true} isString={true} lableVarient="label" name="legend_name" lableValue="Legend Name" value={value.legend_name} onChange={(e) => handlePsdChange(e.target.value, index, "legend_name")} error={errors && !value.legend_name ? true : false} />
                  </Grid>
                  <Grid item sm="6" xs="12">
                    <HourMinites isLable={true} lableVarient="label" name="arrested_time" lableValue="Arrested time" value={value.arrested_time ? new Date(moment(Strdateend + " " + value.arrested_time).format('YYYY-MM-DDTHH:mm:ss')) : ""} onChange={(e) => handlePsdChange(e, index, "arrested_time")} />
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                  </Grid>
                  <Grid item sm="6" xs="12">
                    <Select lableVarient="label" name="arrested_sample" isLable={true} style={{ fontSize: 10 }} lableValue="Arrested Sample" value={value.arrested_sample} onChange={(e) => handlePsdChange(e.target.value, index, "arrested_sample")} error={errors && !value.arrested_sample ? true : false} options={flags} />
                  </Grid>
                  <Grid item sm="6" xs="12">
                    <TextBox isLable={true} isString={true} lableVarient="label" style={{}} lableValue="Attach Documents" uploadImage={<Upload onClick={(e) => handleUpload(e, index)} />} disabled={true} value={value.filename ? value.filename : ""} />
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                      Note: Only Text files are allowed 4.1.22MillExperiment(45MIN)
                    </Typography>
                  </Grid>
                  <Grid item sm="6" xs="12" style={{ display: "flex", justifyContent: 'end', alignItems: "center" }}>
                    {
                      value.filename ?
                        <div style={{ cursor: "pointer" }}>
                          <DownloadIcon onClick={() => downloadFile(value.filename)} />
                        </div> : <></>
                    }

                    {
                      psdData.length - 1 === index &&
                      <div
                        role="button"
                        tabIndex="0"
                        style={{
                          cursor: "pointer",
                          display: "inline-flex",
                          borderRadius: "4px",
                          height: "40px",
                          alignItems: "center",
                          backgroundColor: "#270659"
                        }}
                        onClick={() => addRow(index)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            addRow(index);
                          }
                        }}
                      >
                        <div style={{ marginLeft: "15px", fontSize: 14, color: "#fff" }}>Add Row</div>
                        <AddRow fill='#fff' />
                      </div>
                    }
                    {
                      psdData.length !== 1 &&
                      <Tooltip title="Delete Row" placement="top">
                        <div style={{ cursor: "pointer", marginLeft: "16px", justifyContent: "center", }}>
                          <DeleteRow onClick={() => deleteRow(index)} />
                        </div>
                      </Tooltip>

                    }
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )
        })
      }
      {popupMsg && (
        <Alert
          style={{ width: "87%" }}
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setPopupMsg("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {popupMsg}
        </Alert>
      )}
    </React.Fragment>
  );
}

export default PsdModel;

