import * as React from 'react';
import { AppBar, Box, Toolbar, IconButton, MenuItem, Menu, styled, Button, Avatar } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { ReactComponent as GpsLogo } from '../asset/gyprocLogo.svg';
import ArchiveIcon from '@mui/icons-material/Archive';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 220,
        padding: 10,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            // '&:active': {
            //     backgroundColor: alpha(
            //         theme.palette.primary.main,
            //         theme.palette.action.selectedOpacity,
            //     ),
            // },
        },
    },
}));

const GypsumHeader = () => {
    const [auth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const userDet = localStorage.getItem('loginUserDetails');
    const userValue = JSON.parse(userDet);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        setAnchorEl(null);
        navigate("/login");
    }

    const handleUser = () => {
        setAnchorEl(null);
        navigate("/UserManagement");
    }


    return (
        <React.Fragment>
            <Box >
                <AppBar position="static"  >
                    <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                        <GpsLogo style={{ width: 100, height: 60, color: "whitesmoke" }} />
                        {auth && (
                            <div>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    edge="end"
                                    onClick={handleClick}
                                    color="inherit"
                                    style={{ backgroundColor: "#270659", width: "35px", height: "35px" }}
                                >
                                    <AccountCircle />
                                </IconButton>
                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >

                                    <div style={{ display: "flex", marginLeft: "10px" }}>
                                        <Avatar
                                            style={{ backgroundColor: "#270659", marginRight: 4 }}
                                        >
                                            {userValue.name.charAt(0)}
                                        </Avatar>

                                        <div style={{ fontWeight: 400, fontSize: 14, lineHeight: 1.75 }}>
                                            <div >{userValue.name}</div>
                                            <div >Operator</div>
                                        </div>
                                    </div>
                                    {
                                        userValue.role === "Admin" &&
                                        <MenuItem disableRipple onClick={handleUser} style={{ marginTop: 10 }}>
                                            <ArchiveIcon />
                                            User Management
                                        </MenuItem>
                                    }

                                    <div
                                        style={{
                                            display: "flex",
                                            width: 150,
                                            justifyContent: "center",
                                            margin: "auto",
                                        }}
                                        id="log_out"
                                    >
                                        <Button
                                            variant="contained"
                                            id="logout_button"
                                            size='small'
                                            style={{ width: 160, marginTop: 10, padding: 0, backgroundColor: "#270659" }}
                                            onClick={logout}
                                        >
                                            <div style={{ marginLeft: 15 }}>Logout</div>
                                            <div style={{ marginLeft: 9, marginTop: 7 }}>
                                                <LogoutIcon />
                                            </div>
                                        </Button>
                                    </div>
                                </StyledMenu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
        </React.Fragment>
    );
}

export default GypsumHeader;