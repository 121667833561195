import React from 'react';
import Box from '@mui/material/Box';
import {TextField,InputAdornment} from '@mui/material';
import Typography from '@mui/material/Typography';

const TextBox = (props) => {
  return (
    <Box
  component="form"
  style={{ width: "100%", marginBottom: '16px' }}
  noValidate
  autoComplete="off"
>
  {props.isLable ? (
    <Typography variant="label" style={{ fontSize: '14px', fontWeight: '400' }}>
      {props.lableValue}
    </Typography>
  ) : null}

  <TextField
    id="outlined-basic"
    style={{ width: "100%" }}
    sx={{
      "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      "input[type=number]": {
        MozAppearance: "textfield",
      },
    }}
    label={props.label}
    name={props.name}
    disabled={props.disabled ? props.disabled : null}
    value={props.value}
    type={props.isString || props.name === 'location' ? "text" : "number"}
    inputRef={props.inputRef}
    error={props.error ? props.error : false}
    helperText={props.helperText ? props.helperText : ""}
    accept={props.accept ? props.accept : null}
    onChange={props.onChange}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {props.uploadImage}
        </InputAdornment>
      ),
      inputProps: {
        pattern: props.name === 'location' ? "[A-Za-z]*" : "[0-9]*",
      },
    }}
    variant="outlined"
    size="small"
  />
</Box>

  );
}

export default TextBox;