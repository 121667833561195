
const _get_api_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://dev.aimconnect.api-gypsum.saint-gobain.com/";
    case "prod":
      // return "https://gypsumdashboard-api-prd-fggygwa9cmhghday.centralindia-01.azurewebsites.net/";
      return "https://ctgypsumrddb.api.saint-gobain.com/";
    default:
      return "http://localhost:3002/";
  }
};

const _get_sso_url = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      return "https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_GYPSUM&redirect_uri=https://dev.aimconnect-gypsum.saint-gobain.com/login&service=simple&scope=stGoSGI";
    case "prod":
      return "https://cloudsso.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=Gypsum_Doe_Data_Management&redirect_uri=https://ctgypsumrddb.saint-gobain.com/login&service=simple&scope=stGoSGI";
    default:
      return "https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_GYPSUM&redirect_uri=http://localhost:3000/login&service=simple&scope=stGoSGI";
  }
};

const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const configParam = {
  primaryColor: "#FF6D04",
  lightBackground: "#FC8C200C",
  grey: "#D8D8D8",
  DEEP_COPY: deepCopy,
  API_URL: _get_api_url(),
  SSO_URL: _get_sso_url()
};

export default configParam;
