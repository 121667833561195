import React from 'react';
import Header from "../components/TopAppBar"
import LabTabs from "./LabTabs"
const Visit = () => {
    return (
        <React.Fragment>
            <Header />
            <main>
                <LabTabs />
            </main>

        </React.Fragment>
    );
}

export default Visit;
