import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import TextBox from "../input/TextBox";
import DateTimeMonth from "../input/DateTimeMonth";
import {
  StaticFields,
  CalcinedSection,
  MilledSection,
  DriedSection,
  SievedSection,
  FormedIntoSlurry,
  dryAged,
  combinedSection,
  newSampleSection,
  millMedia,
  millJarType,
  formedSlurryType1,
  formedSlurryType2,
  formedSlurryType3,
  error,
  viewChange,
  uploadFile,
  uploadDriedFile,
  user,
} from "../recoil/atom";
import { useRecoilState } from "recoil";
import GeometryText from "./GeometryText";
import DriedGeometry from "./DriedGeometry";
import HourMinites from "../input/HourMinites";
import Select from "../input/Select";
import configParam from "../Config";
import moment from "moment";
import SampleOption from "../input/SampleOption";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import MaskInput from "../input/MaskInput";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { ReactComponent as AddRow } from "../asset/AddRows.svg";
import { ReactComponent as DeleteRow } from "../asset/deleteIcon.svg";
import { Alert, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Papa from "papaparse";

const deepCopy = configParam.DEEP_COPY;
const ApiURL = configParam.API_URL;

const SampleSelect = forwardRef((props, ref) => {
  const [staticSample] = useRecoilState(StaticFields);
  const [calcinedDet, setCalcinedDet] = useRecoilState(CalcinedSection);
  const [milledDet, setmilledDet] = useRecoilState(MilledSection);
  const [driedDet, setDriedDet] = useRecoilState(DriedSection);
  const [sievedDet, setSeivedDet] = useRecoilState(SievedSection);
  const [formIntoDet, setFormIntoDet] = useRecoilState(FormedIntoSlurry);
  const [dryAgedDet, setDryAgedDet] = useRecoilState(dryAged);
  let [combinedDetails, setCombinedDetails] = useRecoilState(combinedSection);
  const [sampleDet, setSampleDet] = useRecoilState(newSampleSection);
  const [millMediaDet, setMillMediaDet] = useRecoilState(millMedia);
  const [millJarDet, setMillJarDet] = useRecoilState(millJarType);
  const [formedDettype1, setFormedDettype1] = useRecoilState(formedSlurryType1);
  const [formedDettype2, setFormedDettype2] = useRecoilState(formedSlurryType2);
  const [formedDettype3, setFormedDettype3] = useRecoilState(formedSlurryType3);
  const [stepperError, setStepperError] = useRecoilState(error);
  const [, setViewPage] = useRecoilState(viewChange);
  const [millMediadata, setMillMediaData] = useState([]);
  const [rheologymodifiData, setRheologyModifiData] = useState([]);
  const [mixedEquipdata, setMixedEquipdata] = useState([]);
  const [retaterdata, setRetaterdata] = useState([]);
  const [millJarMediadata, setJarMillMediaData] = useState([]);
  const flags1 = ["true", "false"];
  const [errors, setErrors] = useState(false);
  const [millMediaOptions, setMillMediaOptions] = useState([]);
  const [millJarOptions, setMillJarOptions] = useState([]);
  const [millRheOptions, setMillRheOptions] = useState([]);
  const [millMixEquipOptions, setMixEquipOptions] = useState([]);
  const [RetaterOptions, setRetaterOptions] = useState([]);
  const [popupMsg, setPopupMsg] = useState("");
  const [imageName, setImageName] = useRecoilState(uploadFile);
  const [driedFile, setDriedFile] = useRecoilState(uploadDriedFile);
  const [loading] = useState(false);
  const userDetails = localStorage.getItem("loginUserDetails");
  const loginUserDetails = JSON.parse(userDetails);

  const handleCsvUpload = async (event) => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.style.display = "none";
    document.body.appendChild(input);
    input.addEventListener("change", function (e) {
      handleCsvFileChange(e);
    });
    input.click();
  };

  const handleDriedCsvUpload = async (event) => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.style.display = "none";
    document.body.appendChild(input);
    input.addEventListener("change", function (e) {
      handleDriedCsvFileChange(e);
    });
    input.click();
  };

  const handleCsvFileChange = async (event) => {
    let tmpObj = deepCopy(calcinedDet);
    let uploadedFiles = event.target.files;
    let fileUpload = uploadedFiles[0];
    let tmpFileObj = { name: fileUpload.name, type: fileUpload.type };
    let selectedFileName = tmpFileObj.name;
    let selectedType = tmpFileObj.type;
    if (fileUpload.type === "text/csv") {
      const reader = new FileReader();
      reader.onload = () => {
        const csvData = reader.result;
        Papa.parse(csvData, {
          header: true,
          dynamicTyping: true,
          complete: (result) => {
            uploadCsv(result.meta.fields[1], selectedFileName, selectedType);
            tmpObj["file"] = tmpFileObj;
            setCalcinedDet(tmpObj);
          },
          error: (error) => {
            console.error("Error parsing CSV:", error);
          },
        });
      };
      reader.readAsDataURL(fileUpload);
    } else {
      setPopupMsg("Please Select Csv Files");
      setTimeout(() => {
        setPopupMsg("");
      }, 4000);
    }
  };

  const handleDriedCsvFileChange = async (event) => {
    let tmpObj = deepCopy(driedDet);
    let uploadedFiles = event.target.files;
    let fileUpload = uploadedFiles[0];
    let tmpFileObj = { name: fileUpload.name, type: fileUpload.type };
    let selectedFileName = tmpFileObj.name;
    let selectedType = tmpFileObj.type;
    if (fileUpload.type === "text/csv") {
      const reader = new FileReader();
      reader.onload = () => {
        const csvData = reader.result;
        Papa.parse(csvData, {
          header: true,
          dynamicTyping: true,
          complete: (result) => {
            uploadDriedCsv(
              result.meta.fields[1],
              selectedFileName,
              selectedType
            );
            tmpObj["file"] = tmpFileObj;
            setDriedDet(tmpObj);
          },
          error: (error) => {
            console.error("Error parsing CSV:", error);
          },
        });
      };
      reader.readAsDataURL(fileUpload);
    } else {
      setPopupMsg("Please Select Csv Files");
      setTimeout(() => {
        setPopupMsg("");
      }, 4000);
    }
  };
  async function uploadDriedCsv(changePdf64, name, type) {
    let tmpObj = deepCopy(driedDet);
    const url = ApiURL + "upload";
    try {
      const response = await axios.post(url, {
        image: changePdf64,
        imageName: name.toString().replace(/\s+/g, ""),
        fileType: type,
        sampleModal: "dried",
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      });
      setDriedFile(response.data.imageName);
      tmpObj["raw_file"] = response.data.imageName;
      setDriedDet(tmpObj);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }

  const handleFileDownloadClick = () => {
    handleCsvUpload();
  };

  const handleDriedFileDownloadClick = () => {
    handleDriedCsvUpload();
  };

  const downloadFile = (imageName) => {
    const urlName = ApiURL + "download/" + imageName;
    const link = document.createElement("a");
    link.href = urlName;
    link.download = imageName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  async function uploadCsv(changePdf64, name, type) {
    let tmpObj = deepCopy(calcinedDet);
    const url = ApiURL + "upload";
    try {
      const response = await axios.post(url, {
        image: changePdf64,
        imageName: name.toString().replace(/\s+/g, ""),
        fileType: type,
        sampleModal: "calcined",
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      });
      setImageName(response.data.imageName);
      tmpObj["raw_file"] = response.data.imageName;
      setCalcinedDet(tmpObj);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }

  const handleTimeChange = (e, key) => {
    if (key === "time") {
      const temObj = deepCopy(calcinedDet);
      temObj.time = e ? moment(e.$d).format("HH:mm:ss") : "";
      setCalcinedDet(temObj);
    }
    if (key === "calci_time") {
      const temObj = deepCopy(calcinedDet);
      temObj.calci_time = e ? moment(e.$d).format("HH:mm:ss") : "";
      setCalcinedDet(temObj);
    }
    if (key === "calcination_start_time") {
      const temObj = deepCopy(calcinedDet);
      temObj.calcination_start_time = e;
      setCalcinedDet(temObj);
    }
  };

  //  Calcinied OnChange
  const handleCalciniedChange = (e) => {
    const { name, value, id } = e.target;
    if (name === "sample_geometry") {
      let tempObj = JSON.parse(JSON.stringify(calcinedDet));
      tempObj[name][id] = value;
      setCalcinedDet(tempObj);
    } else {
      setCalcinedDet((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleMaskChange = (event) => {
    setCalcinedDet((prevCalcinedDet) => ({
      ...prevCalcinedDet,
      time: event.target.value,
    }));
  };

  // DRIED OnChange
  const handleDriedChange = (e) => {
    const { name, value, id } = e.target;
    if (name === "sample_geometry") {
      let tempObj = JSON.parse(JSON.stringify(driedDet));
      tempObj[name][id] = value;
      setDriedDet(tempObj);
    } else {
      setDriedDet((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // Formed Type OnChange
  const handleFormedTypeChange1 = (e) => {
    const { name, value } = e.target;
    setFormedDettype1((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleFormedTypeChange2 = (e) => {
    const { name, value } = e.target;
    setFormedDettype2((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleFormedTypeChange3 = (e) => {
    const { name, value } = e.target;
    setFormedDettype3((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // Milled Onchange
  //   const millMediaonChange = (e, index) => {
  //     const { name, value } = e.target;
  //     const filteredArr = millMediaOptions.filter(
  //       (item) => item.fullname === value
  //     );
  //     setmilledDet((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));

  //  if(value==="others"){
  //   let data={
  //     material:"",
  //     shape:"",
  //     media_id:""
  //   }
  //   setMillMediaDet(data);
  //  } else if (filteredArr.length > 0) {
  //       let data = {
  //         material: filteredArr[0].material,
  //         shape: filteredArr[0].shape,
  //         media_id: filteredArr[0].media_id,
  //       };
  //       setMillMediaDet(data);
  //     }
  //   };

  const millMediaonChange = (e, index) => {
    const { name, value } = e.target;
    console.log(name, value);
    // Filter the array to find the selected media type
    const filteredArr = millMediaOptions.filter(
      (item) => item.fullname === value
    );
    console.log(filteredArr, millMediaOptions);
    // Update the milledDet state
    setmilledDet((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // If 'others' is selected, reset the material and shape
    if (value === "others") {
      let data = {
        material: "", // Reset material for user input
        shape: "",    // Reset shape for user input
        media_id: ""  // No media_id for 'others'
      };
      console.log(data);
      setMillMediaDet(data);
    } else if (filteredArr.length > 0) {
      // If a valid option is selected, populate the material and shape from the filtered array
      let data = {
        material: filteredArr[0].material,
        shape: filteredArr[0].shape,
        media_id: filteredArr[0].media_id,
      };
      console.log(data);
      setMillMediaDet(data);
    }
  };

  const handleMilledChange = (e) => {
    const { name, value } = e.target;
    setmilledDet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // Milled OnChange
  const handleMilledJarChange = (e) => {
    const { name, value } = e.target;
    const filteredArr1 = millJarOptions.filter(
      (item) => item.fullname === value
    );
    setmilledDet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value === "others") {
      let data = {
        material: "",
        type: "",
        volume: "",
        diameter: "",
        jar_id: ""
      }
      setMillJarDet(data);
    }
    else if (filteredArr1.length > 0) {
      let data1 = {
        material: filteredArr1[0].material,
        type: filteredArr1[0].type,
        volume: filteredArr1[0].volume,
        diameter: filteredArr1[0].diameter,
        jar_id: filteredArr1[0].jar_id,
      };
      setMillJarDet(data1);
    }
  };
  // Milled Media OnChange
  const handleMilledMediaChange = (e) => {
    const { name, value } = e.target;
    setMillMediaDet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // Milled JarType OnChange
  const handleMilleJarChange = (e) => {
    const { name, value } = e.target;
    setMillJarDet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Sieved OnChange
  const handleSievedChange = (e) => {
    const { name, value } = e.target;
    setSeivedDet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Rheology modifier
  const handleRheFormIntoChange = (e) => {
    const { name, value } = e.target;
    const filteredRhe = millRheOptions.filter(
      (item) => item.fullname === value
    );
    setFormIntoDet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (filteredRhe.length > 0) {
      let data = {
        type: filteredRhe[0].type,
        rheology_modifier_id: filteredRhe[0].rheology_modifier_id,
      };
      setFormedDettype1(data);
    }
  };

  const handleMixEquipChange = (e) => {
    const { name, value } = e.target;
    const filteredmix = millMixEquipOptions.filter(
      (item) => item.fullname === value
    );
    setFormIntoDet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (filteredmix.length > 0) {
      let data = {
        type: filteredmix[0].type,
        mixing_equipment_id: filteredmix[0].equipment_id,
      };
      setFormedDettype2(data);
    }
  };

  const handleRetaterIntoChange = (e) => {
    const { name, value } = e.target;
    const filtereRetater = RetaterOptions.filter(
      (item) => item.fullname === value
    );
    setFormIntoDet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (filtereRetater.length > 0) {
      let data = {
        type: filtereRetater[0].type,
        retarder_id: filtereRetater[0].retarder_id,
      };
      setFormedDettype3(data);
    }
  };

  const handleDryMaskChange = (event) => {
    setDryAgedDet((prevCalcinedDet) => ({
      ...prevCalcinedDet,
      aging_duration: event.target.value,
    }));
  };

  const handleDriedMaskChange = (event) => {
    setDriedDet((prevCalcinedDet) => ({
      ...prevCalcinedDet,
      time: event.target.value,
    }));
  };

  const handleformedMaskChange = (event) => {
    setFormIntoDet((prevCalcinedDet) => ({
      ...prevCalcinedDet,
      duration_of_mixing: event.target.value,
    }));
  };

  const handleMilledMaskChange = (event) => {
    setmilledDet((prevCalcinedDet) => ({
      ...prevCalcinedDet,
      duration: event.target.value,
    }));
  };
  const handleComabinedChange = (val, index, key) => {
    let tmpObj = deepCopy(combinedDetails);
    tmpObj[index][key] = val;
    setCombinedDetails(tmpObj);
  };

  useEffect(() => { }, [combinedDetails]);
  // New Sample
  const handleSampleChange = (e) => {
    const { name, value } = e.target;
    setSampleDet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSampleTimeChange = (e, key) => {
    if (key === "date_shipped") {
      const temObj = { ...sampleDet };
      temObj.date_shipped = e ? e.$d : "";
      setSampleDet(temObj);
    }
    if (key === "date_received") {
      const temObj = { ...sampleDet };
      temObj.date_received = e ? e.$d : "";
      setSampleDet(temObj);
    }
  };
  useEffect(() => {
    getOpenMillMediaData();
    getOpenMillJarMediaData();
    getOpenRheology();
    getOpenMixed();
    getOpenRetarder();
  }, []);

  const getOpenMillMediaData = () => {
    let arr = [];
    const url = ApiURL + "get_mill_media";
    axios
      .get(url, {
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((res) => {
        if (res.data.data.res.length > 0) {
          const newArr = res.data.data.res.map((z, index) => {
            const fullId =
              "Material(" + z.material + ") - Shape(" + z.shape + ")";
            arr.push(fullId);
            return {
              fullname: fullId,
              media_id: z.media_id,
              mateial: z.material,
              shape: z.shape,
            };
          });
          arr.push("others");
          setMillMediaOptions(newArr);
          setMillMediaData(arr);
        } else {
          arr.push("others");
          setMillMediaData(arr);
        }
      });
  };

  const getOpenMillJarMediaData = () => {
    let arr2 = [];
    const url = ApiURL + "get_mill_jar_type";
    axios
      .get(url, {
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((res) => {
        if (res.data.data.res.length > 0) {
          const newArr = res.data.data.res.map((z) => {
            const fullId =
              "Material(" +
              z.material +
              ") - Type(" +
              z.type +
              ") - volume(" +
              z.volume +
              ") - diameter(" +
              z.diameter +
              ")";
            arr2.push(fullId);
            return {
              fullname: fullId,
              material: z.material,
              type: z.type,
              volume: z.volume,
              diameter: z.diameter,
              jar_id: z.jar_id,
            };
          });
          arr2.push("others");
          setMillJarOptions(newArr);
          setJarMillMediaData(arr2);
        } else {
          arr2.push("others");
          setJarMillMediaData(arr2);
        }
      });
  };

  const handleFormIntoChange = (e) => {
    const { name, value } = e.target;
    setFormIntoDet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getOpenRheology = () => {
    let rheologyArray = [];
    const url = ApiURL + "get_rheology_modifier";
    axios
      .get(url, {
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((res) => {
        if (res.data.data.res.length > 0) {
          const newArr = res.data.data.res.map((z) => {
            const fullData = "Type (" + z.type + ")";
            rheologyArray.push(fullData);
            return {
              fullname: fullData,
              rheology_modifier_id: z.rheology_modifier_id,
              type: z.type,
            };
          });
          rheologyArray.push("others");
          setMillRheOptions(newArr);
          setRheologyModifiData(rheologyArray);
        } else {
          rheologyArray.push("others");
          setRheologyModifiData(rheologyArray);
        }
      });
  };

  const getOpenMixed = () => {
    let mixedArray = [];
    const url = ApiURL + "get_mix_equipment";
    axios
      .get(url, {
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((res) => {
        if (res.data.data.res.length > 0) {
          const newArr = res.data.data.res.map((z) => {
            const fullData = "Type (" + z.type + ")";
            mixedArray.push(fullData);
            return {
              fullname: fullData,
              type: z.type,
              equipment_id: z.equipment_id,
            };
          });
          mixedArray.push("others");
          setMixEquipOptions(newArr);
          setMixedEquipdata(mixedArray);
        } else {
          mixedArray.push("others");
          setMixedEquipdata(mixedArray);
        }
      });
  };

  const getOpenRetarder = () => {
    let retarderArray = [];
    const url = ApiURL + "get_retarder";
    axios
      .get(url, {
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((res) => {
        if (res.data.data.res.length > 0) {
          const newArr = res.data.data.res.map((z) => {
            const fullData = "Type (" + z.type + ")";
            retarderArray.push(fullData);
            return {
              fullname: fullData,
              retarder_id: z.retarder_id,
              type: z.type,
            };
          });
          retarderArray.push("others");
          setRetaterOptions(newArr);
          setRetaterdata(retarderArray);
        } else {
          retarderArray.push("others");
          setRetaterdata(retarderArray);
        }
      });
  };

  combinedDetails = combinedDetails == undefined ? [] : combinedDetails;

  let tmpObj1 = [];
  const obj1 = {
    parent_ids: "",
    masses: "",
  };
  const addRow = (index) => {
    if (
      combinedDetails[index].parent_ids === "" ||
      combinedDetails[index].masses === ""
    ) {
      setErrors(true);
      setStepperError(true);
    } else {
      const updatedCombinedDetails = [...combinedDetails, obj1];
      setCombinedDetails(updatedCombinedDetails);
      setErrors(false);
      setStepperError(false);
    }
  };
  if (combinedDetails.length === 0) {
    tmpObj1.push(obj1);
    setCombinedDetails(tmpObj1);
  }
  const deleteRow = (index) => {
    let tmpObj = deepCopy(combinedDetails);
    tmpObj.splice(index, 1);
    setCombinedDetails(tmpObj);
  };



  const checkAllValues = (value, mandatoryFields, type) => {
    // MILLED VALIDATION
    let validation = false;
    let durationOrTime;
    if (value.milledTypes === "others") {
      let key = Object.keys(millMediaDet);
      for (let i = 0; i < key.length; i++) {
        if (key[i] === "media_id") {
          return false;
        } else {
          if (!millMediaDet[key[i]]) {
            return true;
          }
        }
      }
    }

    if (value.millJarTypes === "others") {
      let key = Object.keys(millJarDet);
      for (let i = 0; i < key.length; i++) {
        if (key[i] === "jar_id") {
          return false;
        } else if (!millJarDet[key[i]]) {
          return true;
        }
      }
    }

    if (type === "Calcined" || type === "Dried") {
      if (
        value.sample_geometry.length === "" ||
        value.sample_geometry.width === "" ||
        value.sample_geometry.height === ""
      ) {
        return true;
      }
    }

    if (type === "Dry Aged") {
      if (value.aging_duration) {
        if (type === "Dry Aged") {
          if (value.aging_duration) {
            let parts = value.aging_duration.split(":");
            let newParts = parts.filter((part) => part.trim() !== "");
            if (newParts.length < 3) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }


    if (value.formedTypes === "others") {
      if (formedDettype1.type1 === "") {
        return true;
      }
    }
    if (value.mixedSluryTypes === "others") {
      if (formedDettype2.type2 === "") {
        return true;
      }
    }
    if (value.retaterTypes === "others") {
      if (formedDettype3.type3 === "") {
        return true;
      }
    }
    for (let i = 0; i < mandatoryFields.length; i++) {
      const field = mandatoryFields[i];
      if (field === "sample_temperature_measured") {
        if (value[field] !== undefined && value[field] !== null) {
          if (value[field].toString() === "") {
            return true;
          }
          if ((value[field] === true || value[field] === "true") && !value.raw_file) {
            return true;
          }
        } else {
          return false;
        }
      } else if (!value[field]) {
        return true;
      }
    }
    return validation;
  };

  const validationCheck = () => {
    let validation;
    let mandatoryFields = [];
    switch (props.value) {
      case "Calcined":
        mandatoryFields = [
          "power",
          "frequency",
          "sample_mass",
          "location",
          "sample_packing",
          "sample_temperature_measured",
          "calcination_start_time",
          "time",
          "sample_geometry",
          "calci_time",
        ];
        validation = checkAllValues(calcinedDet, mandatoryFields, props.value);
        break;
      case "Milled":
        mandatoryFields = [
          "starting_material_id",
          "media_mass",
          "sample_mass",
          "rpm",
          "duration",
        ];
        validation = checkAllValues(milledDet, mandatoryFields);

        break;
      case "Dried":
        mandatoryFields = [
          "fan_speed",
          "oven_temperature",
          "relative_humidity",
          "initial_sample_weight",
          "sample_geometry",
          "final_sample_weight",
          "sample_temperature_measured",
          "time",
        ];
        validation = checkAllValues(driedDet, mandatoryFields, props.value);
        break;
      case "Sieved":
        mandatoryFields = [
          "upper_bin_size",
          "lower_bin_size",
          "sieve_type",
          "sieve_duration",
          "sieve_pressure",
          "mass_before",
          "mass_retained",
          "mass_through",
        ];
        validation = checkAllValues(sievedDet, mandatoryFields);
        break;
      case "Formed Into Slurry":
        mandatoryFields = [
          "water_gauge",
          "retarder_amount",
          "power_setting",
          "duration_of_mixing",
          "solid_loading",
        ];
        validation = checkAllValues(formIntoDet, mandatoryFields);
        break;
      case "Dry Aged":
        mandatoryFields = ["aging_duration"];
        validation = checkAllValues(dryAgedDet, mandatoryFields, props.value);
        break;
      // case "Combined":
      //   mandatoryFields = ["parent_ids", "masses"];
      //   validation = checkAllValues(combinedDetails, mandatoryFields);
      //   break;
      case "New Arrival":
        mandatoryFields = [
          "origin",
          "type",
          "lot_number",
          "date_shipped",
          "date_received",
          "received_at",
        ];
        validation = checkAllValues(sampleDet, mandatoryFields);
        break;
      default:
    }

    if (
      staticSample.entry_date === "" ||
      staticSample.oddity_flag === "" ||
      staticSample.parent_id === ""
    ) {
      validation = true;
    }
    if (validation) {
      setStepperError(true);
      setViewPage(false);
    } else {
      setStepperError(false);
      setViewPage(true);
    }
  };
  useImperativeHandle(ref, () => ({
    validationCheck: () => validationCheck(),
  }));

  let getTime = new Date();
  const Strdateend = getTime.toISOString().split("T").shift();
  const selectHandle = () => {
    switch (props.value) {
      case "Calcined":
        return (
          <>
            <div style={{ width: "100%" }}>
              <MaskInput
                isLable={true}
                lableValue="Time HHH:MM:SS*"
                value={calcinedDet.time}
                onChange={handleMaskChange}
                error={stepperError && !calcinedDet.time}
                helperText={stepperError && calcinedDet.time.length < 9 ? "Please Complete HHH:MM:SS" : false}
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Power (watts) *"
                name="power"
                value={calcinedDet.power}
                onChange={handleCalciniedChange}
                error={stepperError && !calcinedDet.power}
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Frequency(kHz) *"
                name="frequency"
                value={calcinedDet.frequency}
                onChange={handleCalciniedChange}
                error={stepperError && !calcinedDet.frequency}
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Location *"
                name="location"
                value={calcinedDet.location}
                isString={true}
                onChange={handleCalciniedChange}
                error={stepperError && !calcinedDet.location}
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Sample Mass (grams) *"
                name="sample_mass"
                value={calcinedDet.sample_mass}
                onChange={handleCalciniedChange}
                error={stepperError && !calcinedDet.sample_mass}
              />
              <GeometryText
                isLable={true}
                lableVarient="label"
                lableValue="Sample Geometry (inches)*"
                name="sample_geometry"
                value={calcinedDet.sample_geometry}
                onChange={handleCalciniedChange}
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Sample Packing (%) *"
                name="sample_packing"
                value={calcinedDet.sample_packing}
                onChange={handleCalciniedChange}
                error={stepperError && !calcinedDet.sample_packing}
              />
              <DateTimeMonth
                isLable={true}
                lableVarient="label"
                lableValue="Calcination Start Time (mm:dd:yyyy) *"
                name="calcination_start_time"
                value={calcinedDet.calcination_start_time}
                onChange={(e) => handleTimeChange(e, "calcination_start_time")}
                error={stepperError && !calcinedDet.calcination_start_time}
                disabled={true}
              />
              <HourMinites
                isLable={true}
                lableVarient="label"
                lableValue="Additional Time (hh:mm:ss)*"
                name="calci_time"
                value={
                  calcinedDet.calci_time
                    ? new Date(
                      moment(
                        Strdateend + " " + calcinedDet.calci_time
                      ).format("YYYY-MM-DDTHH:mm:ss")
                    )
                    : ""
                }
                onChange={(e) => handleTimeChange(e, "calci_time")}
                error={stepperError && !calcinedDet.calci_time}
              />

              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <SampleOption
                    lableVarient="label"
                    name="sample_temperature_measured"
                    isLable={true}
                    lableValue="Sample Temperature Measured (degrees C)*"
                    value={calcinedDet.sample_temperature_measured}
                    onChange={handleCalciniedChange}
                    options={flags1}
                    error={
                      stepperError &&
                        calcinedDet.sample_temperature_measured.toString() === ""
                        ? true
                        : false
                    }
                    helperText={
                      stepperError &&
                        (calcinedDet.sample_temperature_measured === "true" ||
                          (calcinedDet.sample_temperature_measured === true &&
                            !calcinedDet.raw_file)) &&
                        !calcinedDet.raw_file // Hide helper text if file is present
                        ? "Please Uplod File.."
                        : false
                    }
                  />
                </div>
                <div>
                  {calcinedDet.sample_temperature_measured === "true" ||
                    calcinedDet.sample_temperature_measured === true ? (
                    <div
                      style={{
                        backgroundColor: "#005EB8",
                        width: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        marginTop: "20px",
                        height: "40px",
                      }}
                    >
                      <FileUploadIcon
                        style={{ color: "#FFFF" }}
                        onClick={handleFileDownloadClick}
                      />
                    </div>
                  ) : null}
                  <input
                    type="file"
                    accept=".csv"
                    style={{ display: "none" }}
                    onChange={handleCsvFileChange}
                  />
                </div>
              </div>
            </div>
            {calcinedDet.raw_file &&
              (calcinedDet.sample_temperature_measured === "true" ||
                calcinedDet.sample_temperature_measured === true) && (
                <div>
                  {loading ? (
                    <CircularProgress style={{ color: "blue" }} />
                  ) : (
                    <a
                      href="#"
                      onClick={() => downloadFile(calcinedDet.raw_file)}
                      style={{ color: "blue" }}
                    >
                      Click here to download the CSV file
                    </a>
                  )}
                </div>
              )}
          </>
        );
      case "Milled":
        return (
          <div>
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Starting Material id *"
              name="starting_material_id"
              value={milledDet.starting_material_id}
              onChange={handleMilledChange}
              error={
                stepperError && !milledDet.starting_material_id ? true : false
              }
            />
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Media Mass (grams)*"
              name="media_mass"
              value={milledDet.media_mass}
              onChange={handleMilledChange}
              error={stepperError && !milledDet.media_mass ? true : false}
            />

            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Sample Mass (grams) *"
              name="sample_mass"
              value={milledDet.sample_mass}
              onChange={handleMilledChange}
              error={stepperError && !milledDet.sample_mass ? true : false}
            />

            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="RPM (revolutions per minute) *"
              name="rpm"
              value={milledDet.rpm}
              onChange={handleMilledChange}
              error={stepperError && !milledDet.rpm ? true : false}
            />
            <MaskInput
              isLable={true}
              lableValue="Duration (hhh:mm:ss) *"
              value={milledDet.duration}
              onChange={handleMilledMaskChange}
            />
            <Select
              lableVarient="label"
              name="milledTypes"
              isLable={true}
              value={milledDet.milledTypes}
              lableValue="Mill Media"
              onChange={millMediaonChange}
              options={millMediadata}
            />

            {milledDet.milledTypes === "others" && (
              <div>
                <TextBox
                  isLable={true}
                  lableVarient="label"
                  isString={true}
                  lableValue="Material *"
                  name="material"
                  value={millMediaDet?.material}
                  onChange={handleMilledMediaChange}
                  error={
                    stepperError && millMediaDet && !millMediaDet.material
                      ? true
                      : false
                  }
                />
                <TextBox
                  isLable={true}
                  lableVarient="label"
                  isString={true}
                  lableValue="Shape *"
                  name="shape"
                  value={millMediaDet?.shape}
                  onChange={handleMilledMediaChange}
                  error={
                    stepperError && millMediaDet && !millMediaDet.shape
                      ? true
                      : false
                  }
                />
              </div>
            )}
            <div style={{ marginTop: 10 }}>
              <Typography style={{ fontSize: "14px" }}>
                Mill Jar Type{" "}
              </Typography>
              <Select
                lableVarient="label"
                name="millJarTypes"
                isLable={true}
                value={milledDet.millJarTypes}
                onChange={handleMilledJarChange}
                options={millJarMediadata}
              />

              {milledDet.millJarTypes === "others" && (
                <div>
                  <TextBox
                    isLable={true}
                    lableVarient="label"
                    lableValue="Material *"
                    isString={true}
                    name="material"
                    value={millJarDet.material}
                    onChange={handleMilleJarChange}
                    error={stepperError && !millJarDet.material ? true : false}
                  />
                  <TextBox
                    isLable={true}
                    lableVarient="label"
                    lableValue="Type *"
                    isString={true}
                    name="type"
                    value={millJarDet.type}
                    onChange={handleMilleJarChange}
                    error={stepperError && !millJarDet.type ? true : false}
                  />
                  <TextBox
                    isLable={true}
                    lableVarient="label"
                    lableValue="Volume (mL)*"
                    name="volume"
                    value={millJarDet.volume}
                    onChange={handleMilleJarChange}
                    error={stepperError && !millJarDet.volume ? true : false}
                  />
                  <TextBox
                    isLable={true}
                    lableVarient="label"
                    lableValue="Diameter (cm) *"
                    name="diameter"
                    value={millJarDet.diameter}
                    onChange={handleMilleJarChange}
                    error={stepperError && !millJarDet.diameter ? true : false}
                  />
                </div>
              )}
            </div>
          </div>
        );

      case "Dried":
        return (
          <>
            <div>
              <MaskInput
                isLable={true}
                lableValue="Time (hhh:mm:ss)*"
                value={driedDet.time}
                onChange={handleDriedMaskChange}
                error={stepperError && !driedDet.time ? true : false}
              />

              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Fan Speed (revolutions per minute)*"
                name="fan_speed"
                value={driedDet.fan_speed}
                onChange={handleDriedChange}
                error={stepperError && !driedDet.fan_speed ? true : false}
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Oven Temperature (degrees C) *"
                name="oven_temperature"
                value={driedDet.oven_temperature}
                onChange={handleDriedChange}
                error={
                  stepperError && !driedDet.oven_temperature ? true : false
                }
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Relative Humidity *"
                name="relative_humidity"
                value={driedDet.relative_humidity}
                onChange={handleDriedChange}
                error={
                  stepperError && !driedDet.relative_humidity ? true : false
                }
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Initial Sample Weight (grams)*"
                name="initial_sample_weight"
                value={driedDet.initial_sample_weight}
                onChange={handleDriedChange}
                error={
                  stepperError && !driedDet.initial_sample_weight ? true : false
                }
              />
              <DriedGeometry
                isLable={true}
                lableVarient="label"
                lableValue="Sample Geometry (inches)*"
                name="sample_geometry"
                value={driedDet.sample_geometry}
                onChange={handleDriedChange}
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Final Sample Weight (grams)*"
                name="final_sample_weight"
                value={driedDet.final_sample_weight}
                onChange={handleDriedChange}
                error={
                  stepperError && !driedDet.final_sample_weight ? true : false
                }
              />
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <SampleOption
                    lableVarient="label"
                    name="sample_temperature_measured"
                    isLable={true}
                    lableValue="Sample Temperature Measured (degrees C) *"
                    value={driedDet.sample_temperature_measured}
                    onChange={handleDriedChange}
                    error={
                      stepperError &&
                        driedDet.sample_temperature_measured &&
                        driedDet.sample_temperature_measured.toString() === ""
                        ? true
                        : false
                    }
                    helperText={
                      stepperError &&
                        (driedDet.sample_temperature_measured === "true" ||
                          (driedDet.sample_temperature_measured === true &&
                            !driedDet.raw_file)) &&
                        !driedDet.raw_file // Hide helper text if file is present
                        ? "Plese Upload File.."
                        : ""
                    }
                    options={flags1}
                  />
                </div>
                <div>
                  {driedDet.sample_temperature_measured === "true" ||
                    driedDet.sample_temperature_measured === true ? (
                    <div
                      style={{
                        backgroundColor: "#005EB8",
                        width: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        marginTop: "20px",
                        height: "40px",
                      }}
                    >
                      <FileUploadIcon
                        style={{ color: "#FFFF" }}
                        onClick={handleDriedFileDownloadClick}
                      />
                    </div>
                  ) : null}
                  <input
                    type="file"
                    accept=".csv"
                    style={{ display: "none" }}
                    onChange={handleDriedCsvFileChange}
                  />
                </div>
              </div>
            </div>
            {driedDet.raw_file &&
              (driedDet.sample_temperature_measured === "true" ||
                driedDet.sample_temperature_measured === true) && (
                <div>
                  {loading ? (
                    <CircularProgress style={{ color: "blue" }} />
                  ) : (
                    <a
                      href="#"
                      onClick={() => downloadFile(driedDet.raw_file)}
                      style={{ color: "blue" }}
                    >
                      Click here to download the CSV file
                    </a>
                  )}
                </div>
              )}
          </>
        );
      case "Sieved":
        return (
          <div>
            {/* <TextBox isLable={true} lableVarient="label" lableValue="Sample Number" value={staticSample.sample_number} disabled /> */}
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Upper Bin Size (micrometer)*"
              name="upper_bin_size"
              value={sievedDet.upper_bin_size}
              onChange={handleSievedChange}
              error={stepperError && !sievedDet.upper_bin_size ? true : false}
            />
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Lower Bin Size (micrometer) *"
              name="lower_bin_size"
              value={sievedDet.lower_bin_size}
              onChange={handleSievedChange}
              error={stepperError && !sievedDet.lower_bin_size ? true : false}
            />
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Sieve Type *"
              isString={true}
              name="sieve_type"
              value={sievedDet.sieve_type}
              onChange={handleSievedChange}
              error={stepperError && !sievedDet.sieve_type ? true : false}
            />
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Sieve Duration (minutes) *"
              name="sieve_duration"
              value={sievedDet.sieve_duration}
              onChange={handleSievedChange}
              error={stepperError && !sievedDet.sieve_duration ? true : false}
            />
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Sieve Pressure (psi) *"
              name="sieve_pressure"
              value={sievedDet.sieve_pressure}
              onChange={handleSievedChange}
              error={stepperError && !sievedDet.sieve_pressure ? true : false}
            />
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Mass Before (grams) *"
              name="mass_before"
              value={sievedDet.mass_before}
              onChange={handleSievedChange}
              error={stepperError && !sievedDet.mass_before ? true : false}
            />
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Mass Retained (grams) *"
              name="mass_retained"
              value={sievedDet.mass_retained}
              onChange={handleSievedChange}
              error={stepperError && !sievedDet.mass_retained ? true : false}
            />
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Mass Through (grams) *"
              name="mass_through"
              value={sievedDet.mass_through}
              onChange={handleSievedChange}
              error={stepperError && !sievedDet.mass_through ? true : false}
            />
            {/* <TextField label="Add Notes" name="addNotes" value={sievedDet.addNotes} onChange={handleSievedChange} /> */}
          </div>
        );
      case "Formed Into Slurry":
        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Water Gauge% *"
                name="water_gauge"
                value={formIntoDet.water_gauge}
                onChange={handleFormIntoChange}
                error={stepperError && !formIntoDet.water_gauge ? true : false}
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Retarder Amount (grams)  *"
                name="retarder_amount"
                value={formIntoDet.retarder_amount}
                onChange={handleFormIntoChange}
                error={
                  stepperError && !formIntoDet.retarder_amount ? true : false
                }
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Power Setting *"
                name="power_setting"
                value={formIntoDet.power_setting}
                onChange={handleFormIntoChange}
                error={
                  stepperError && !formIntoDet.power_setting ? true : false
                }
              />
              {/* <HourMinites isLable={true} lableVarient="label" lableValue="Duration Of Mixing (hh:mm:ss)*" name="duration_of_mixing" value={formIntoDet.duration_of_mixing ? new Date(moment(Strdateends + " " + formIntoDet.duration_of_mixing).format('YYYY-MM-DDTHH:mm:ss')) : ""} onChange={(e) => handleDurationChange(e, "duration_of_mixing")} error={stepperError && !formIntoDet.duration_of_mixing ? true : false} /> */}
              <MaskInput
                isLable={true}
                lableValue="Duration of mixing (hhh:mm:ss)*"
                value={formIntoDet.duration_of_mixing}
                onChange={handleformedMaskChange}
                error={
                  stepperError && !formIntoDet.duration_of_mixing ? true : false
                }
              />

              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Rheology Modifier Amount"
                name="rheology_modifier_amount"
                value={formIntoDet.rheology_modifier_amount}
                onChange={handleFormIntoChange}
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Additives"
                isString={true}
                name="additives"
                value={formIntoDet.additives}
                onChange={handleFormIntoChange}
              />
              <TextBox
                isLable={true}
                lableVarient="label"
                lableValue="Solid Loading (grams) *"
                name="solid_loading"
                value={formIntoDet.solid_loading}
                onChange={handleFormIntoChange}
                error={
                  stepperError && !formIntoDet.solid_loading ? true : false
                }
              />
              <div></div>
              <Typography style={{ fontSize: "14px" }}>
                Rheology modifier *{" "}
              </Typography>
              <Select
                lableVarient="label"
                name="formedTypes"
                isLable={true}
                value={formIntoDet.formedTypes}
                onChange={handleRheFormIntoChange}
                options={rheologymodifiData}
              />
              <div>
                {formIntoDet.formedTypes === "others" && (
                  <TextBox
                    isLable={true}
                    lableVarient="label"
                    lableValue="Type"
                    isString={true}
                    name="type1"
                    value={formedDettype1.type1}
                    onChange={handleFormedTypeChange1}
                    error={stepperError && !formedDettype1.type1 ? true : false}
                  />
                )}

                <div>
                  <Typography style={{ fontSize: "14px" }}>
                    Mix equipment *{" "}
                  </Typography>
                  <Select
                    lableVarient="label"
                    name="mixedSluryTypes"
                    isLable={true}
                    value={formIntoDet.mixedSluryTypes}
                    onChange={handleMixEquipChange}
                    options={mixedEquipdata}
                  />
                  {formIntoDet.mixedSluryTypes === "others" && (
                    <TextBox
                      isLable={true}
                      lableVarient="label"
                      lableValue="Type"
                      name="type2"
                      isString={true}
                      value={formedDettype2.type2}
                      onChange={handleFormedTypeChange2}
                      error={
                        stepperError && !formedDettype2.type2 ? true : false
                      }
                    />
                  )}
                </div>
                <div>
                  <Typography style={{ fontSize: "14px" }}>
                    Retarder *{" "}
                  </Typography>
                  <Select
                    lableVarient="label"
                    name="retaterTypes"
                    isLable={true}
                    value={formIntoDet.retaterTypes}
                    onChange={handleRetaterIntoChange}
                    options={retaterdata}
                  />

                  {formIntoDet.retaterTypes === "others" && (
                    <TextBox
                      isLable={true}
                      lableVarient="label"
                      lableValue="Type"
                      name="type3"
                      isString={true}
                      value={formedDettype3.type3}
                      onChange={handleFormedTypeChange3}
                      error={
                        stepperError && !formedDettype3.type3 ? true : false
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case "Dry Aged":
        return (
          <div>
            <MaskInput
              isLable={true}
              lableValue="Aging Duration (yy:mm:dd)*"
              calender={true}
              value={dryAgedDet.aging_duration}
              onChange={handleDryMaskChange}
              error={
                stepperError &&
                  !dryAgedDet.aging_duration ? true : false

              }
              helperText={stepperError && dryAgedDet.aging_duration.length < 8 ? "please Complete YY:MM:SS" : false}
            />
          </div>
        );
      case "Combined":
        return (
          <div>
            {combinedDetails.map((value, index) => (
              <div>
                <TextBox
                  isLable={true}
                  lableVarient="label"
                  lableValue="Parent Ids *"
                  name="parent_ids"
                  value={value.parent_ids}
                  onChange={(e) =>
                    handleComabinedChange(e.target.value, index, "parent_ids")
                  }
                  error={errors && !value.parent_ids ? true : false}
                />
                <TextBox
                  isLable={true}
                  lableVarient="label"
                  lableValue="Masses (grams) *"
                  name="masses"
                  value={value.masses}
                  onChange={(e) =>
                    handleComabinedChange(e.target.value, index, "masses")
                  }
                  error={errors && !value.masses ? true : false}
                />

                {combinedDetails.length - 1 === index && (
                  <div
                    role="button"
                    tabIndex="0"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      borderRadius: "4px",
                      height: "40px",
                      alignItems: "center",
                      background: "#1976d2",
                      marginLeft: "auto",
                      backgroundColor: "#270659",
                      width: "100px",
                    }}
                    onClick={() => addRow(index)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        addRow(index);
                      }
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "10px",
                        fontSize: 14,
                        color: "#fff",
                      }}
                    >
                      Add Row
                    </div>
                    <AddRow fill="#fff" />
                  </div>
                )}
                <div style={{ marginBottom: "20px" }}>
                  {combinedDetails.length !== 1 && (
                    <Tooltip title="Delete Row" placement="top">
                      <div
                        style={{
                          cursor: "pointer",
                          marginLeft: "16px",
                          justifyContent: "center",
                        }}
                      >
                        <DeleteRow onClick={() => deleteRow(index)} />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        );
      case "New Arrival":
        return (
          <div>
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Origin *"
              isString={true}
              name="origin"
              value={sampleDet.origin}
              onChange={handleSampleChange}
              error={stepperError && !sampleDet.origin ? true : false}
            />
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Type *"
              isString={true}
              name="type"
              value={sampleDet.type}
              onChange={handleSampleChange}
              error={stepperError && !sampleDet.type ? true : false}
            />
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Lot Number *"
              name="lot_number"
              value={sampleDet.lot_number}
              onChange={handleSampleChange}
              error={stepperError && !sampleDet.lot_number ? true : false}
            />
            <DateTimeMonth
              isLable={true}
              lableVarient="label"
              lableValue="Date Shipped  (mm:dd:yyyy) *"
              name="date_shipped"
              value={sampleDet.date_shipped}
              onChange={(e) => handleSampleTimeChange(e, "date_shipped")}
              error={stepperError && !sampleDet.date_shipped ? true : false}
            />
            <DateTimeMonth
              isLable={true}
              lableVarient="label"
              lableValue="Date Received  (mm:dd:yyyy) *"
              name="date_received"
              value={sampleDet.date_received}
              onChange={(e) => handleSampleTimeChange(e, "date_received")}
              error={stepperError && !sampleDet.date_received ? true : false}
            />
            <TextBox
              isLable={true}
              lableVarient="label"
              lableValue="Received at *"
              isString={true}
              name="received_at"
              value={sampleDet.received_at}
              onChange={handleSampleChange}
              error={stepperError && !sampleDet.received_at ? true : false}
            />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div>
      {selectHandle()}
      {popupMsg && (
        <Alert
          style={{ width: "100%" }}
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setPopupMsg("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {popupMsg}
        </Alert>
      )}
    </div>
  );
});
export default SampleSelect;
