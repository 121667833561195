import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react';
import TextBox from '../input/TextBox';
import DateTimeMonth from '../input/DateTimeMonth';
import { StaticFields, user } from "../recoil/atom"
import { useRecoilState } from "recoil";
import OperatorAutocomplte from "../input/OperatorAutocomplete"
import configParam from "../Config";
import AddIcon from '@mui/icons-material/Add';
import { Modal, Box, Button, IconButton, Typography, TextField } from "@mui/material";
import Alert from '@mui/material/Alert';
import CloseIcon from "@mui/icons-material/Close";
import SampleOption from '../input/SampleOption';
import axios from 'axios';
import TextArea from '../input/TextArea';

const ApiURL = configParam.API_URL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const StaticSample = forwardRef((props, ref) => {
  const [staticSample, setStaticSample] = useRecoilState(StaticFields);
  const [stepperError, setStepperError] = useState(false);
  const[error,setError]=useState(false)
  const [operators, setOperator] = useState([]);
  const [show, setShow] = useState(false);
  const [popupMsg, setPopupMsg] = useState("");
  const nameRef = useRef();
  const operatorIdRef = useRef();
  const flags = ["true", "false"];
  const userDetails = localStorage.getItem("loginUserDetails");
  const loginUserDetails = JSON.parse(userDetails);

  useEffect(() => {
    getOpearatorsDetails();
  }, [])

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setStaticSample(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  const handleEntryChange = (e) => {
    setStaticSample(prevState => ({
      ...prevState,
      entry_date: e ? e.$d : ""
    }));
  }

  const staticErrorChange = () => {
    if (!staticSample.entry_date || !staticSample.oddity_flag) {
      setStepperError(true);
    } else {
      setStepperError(false);
    }
  }

  useImperativeHandle(ref, () => ({
    staticErrorChange: () => staticErrorChange()

  }))

  const getOpearatorsDetails = async () => {
    const url = ApiURL + "get_autocompleteOperator";
    try {
      const response = await axios.get(url, {
        headers: {
          authorization: loginUserDetails.web_token
        }
      });
      const operatorArray = response.data.data.res.map((items) => items.operator_id);
      setOperator(operatorArray);
    } catch (error) {
      console.error("Error fetching operators:", error);
      setOperator([]);
    }
  };




  const autocompleteChange = (e, key) => {
    let tmpObj = { ...staticSample };
    tmpObj[key] = e.target.innerText;
    setStaticSample(tmpObj);

  }

  const handleClose = () => {
    setShow(false);

  };
  const showPopUp = () => {
    setShow(true);
    setError(false);
    nameRef.current.value = "";
    operatorIdRef.current.value = ""
  };

  const addValidation = () => {
    if (nameRef.current.value === "" || operatorIdRef.current.value === "") {
      setError(true);
      return false; // Return false to indicate validation failure
    } else {
      setError(false);
      return true; // Return true to indicate validation success
    }
  };

  const onHandleSave = () => {
    if (!addValidation()) {
      return;
    }
    addValidation();
    const url = ApiURL + "additional_operators";
    axios
      .post(
        url,
        {
          name: nameRef.current.value,
          operator_id: operatorIdRef.current.value,
        },
        {
          headers: {
            authorization: loginUserDetails.web_token,
            //secret_key: loginUserDetails.web_secret,
          },
        }
      )
      .then((data) => {

        if (Object.keys(data.data.data.user).length) {
          setPopupMsg("Data Successfully Added");
          handleClose();
          getOpearatorsDetails();
          setTimeout(() => {
            setPopupMsg("");
          }, 3000);
        }
      });
  };
  

  return (
    <React.Fragment>
      <div style={{ width: "100%" }}>
        {popupMsg && (
          <Alert
            style={{ width: "100%" }}
            variant="filled"
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPopupMsg("");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {popupMsg}
          </Alert>
        )}

        <Typography
          variant="label"
          style={{ fontSize: '14px' }}
          gutterBottom
          title="TrialNo"
        >
          Sample No *
        </Typography>
        <TextField
          id="outlined-multiline-static"
          name="sample_number"
          size='small'
          style={{ width: "100%", marginBottom: '16px' }}
          disabled={true}
          value={staticSample.sample_number}

        />
        <Typography variant="label"
          style={{ fontSize: '14px' }}
          gutterBottom>
          Entry Date *
        </Typography>
        <DateTimeMonth isLable={true} name="Entry_date" size="small" value={staticSample.entry_date} lableVarient="body2" onChange={handleEntryChange} error={stepperError && !staticSample.entry_date} />
        <Typography variant="label"
          style={{ fontSize: '14px' }}
          gutterBottom>
          Additional Operators Id
        </Typography>
        <div style={{ width: "100%", display: "flex", marginBottom: '16px' }}>
          <div style={{ width: '100%' }}>
            <OperatorAutocomplte isLable={true} name="additional_operator_ids" lableVarient="body2" myOptions={operators} value={staticSample.additional_operator_ids} onChange={(e) => autocompleteChange(e, "additional_operator_ids")} />
          </div>
          <div style={{ backgroundColor: "#005EB8", width: "50px", display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
            <AddIcon style={{ color: "#FFFF" }} onClick={showPopUp} />
          </div>

        </div>
        <Typography variant="label"
          style={{ fontSize: '14px' }}
          gutterBottom>
          Parent Id *
        </Typography>
        <TextBox isLable={true} size="small" name="parent_id" value={staticSample.parent_id} onChange={handleFieldChange} error={stepperError && !staticSample.parent_id} />
        <SampleOption lableVarient="body2" name="oddity_flag" isLable={true} lableValue="Oddity flag *" value={staticSample.oddity_flag} onChange={handleFieldChange} options={flags} error={stepperError && staticSample.oddity_flag === ""} />
        <Typography variant="label"
          style={{ fontSize: '14px' }}
          gutterBottom>
          Moisture Content
        </Typography>
        <TextBox isLable={true} name="moisture_content" value={staticSample.moisture_content} onChange={handleFieldChange} />
        <TextArea isLable={true} name="addNotes" lableValue='Add Notes' value={staticSample.addNotes} onChange={handleFieldChange} />
      </div>
      <div>

        <Modal
          keepMounted
          open={show}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >

          <Box sx={style}>
            <Typography>Operator Name</Typography>
            <TextBox
              inputRef={nameRef}
              isString={true}
              variant="outlined"
              style={{ width: "100%" }}
              error={error && nameRef.current.value === ""}
            />
            <br />
            <div style={{ marginTop: 10 }}>
              <Typography>Operator Id</Typography>
              <TextBox
                inputRef={operatorIdRef}
                isString={true}
                variant="outlined"
                style={{ width: "100%" }}
                error={error && operatorIdRef.current.value===""}
              />
            </div>
            <br />
            <div
              style={{ display: "flex", alignItems: "center", float: "right" }}
            >
              <Button
                variant="contained"
                style={{ marginTop: 20 }}
                onClick={onHandleSave}
              >
                Save
              </Button>
              <Button
                variant="contained"
                style={{ marginTop: 20, marginLeft: 20 }}
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </React.Fragment>
  );
}
)
export default StaticSample;
