import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as GpsLogo } from "../asset/gyprocLogo.svg";
import configParam from "../Config";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRecoilState } from "recoil";
import { user } from "../recoil/atom";
import { ReactComponent as LoginImage } from "../asset/loginImage.svg";
import axios from "axios";

const ApiURL = configParam.API_URL;

const Login = () => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [, setUser] = useRecoilState(user);

  useEffect(() => {
    loginSSO();
  }, []); // Empty array to only run on mount

  const getUserDetails = async (id, token) => {
    try {
      const url = `${ApiURL}get_loginOperators?operator_id=${id}`;
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${token.replace(/"/g, "")}`,
        },
      });

      if (response.data.data.user[0]) {
        const userData = response.data.data.user[0];
        userData.web_token = `Bearer ${token}`;
        localStorage.setItem("loginUserDetails", JSON.stringify(userData));
        setUser({
          sgid: userData.operator_id,
          name: userData.name,
          operator_id: userData.operator_id,
          web_token: userData.web_token,
        });
      } else {
        setError("User data not found");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      setError("Failed to retrieve user details");
    }
  };

  const loginSSO = async () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get("code");

    if (token) {
      setLoader(true); // Start loading
      try {
        const response = await axios.get(`${ApiURL}authorize?token=${token}`);

        if (response.status === 200) {
          const userData = response.data;

          if (userData && userData.data) {
            await getUserDetails(userData.data.sgid, userData.data.access_token);
            navigate(`/visit`);
          } else {
            setError("Access denied. User data is missing.");
            localStorage.setItem("loginUserDetails", "");
          }
        }
      } catch (error) {
        console.error("Error during authentication:", error);
        setError("Authentication failed");
      } finally {
        setLoader(false); // Stop loading
      }
    }
  };

  const handleSSO = () => {
    window.location.href = configParam.SSO_URL;
  };

  return (
    <div>
      <Grid
        container
        className="loginBG"
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          marginTop: "0",
          padding: "60px 80px",
        }}
      >
        <Grid
          item
          md={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#efefef",
            height: "100%",
            position: "relative",
            zIndex: 1,
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        >
          <LoginImage style={{ width: "70%", height: "auto" }} />
        </Grid>
        <Grid
          item
          md={6}
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            height: "100%",
            background: "#fff",
            position: "relative",
            zIndex: 1,
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <GpsLogo style={{ width: "180px" }} />
            <Typography
              variant="h2"
              style={{
                color: "#000",
                fontSize: "42px",
                textAlign: "center",
                fontWeight: "600",
                margin: "30px 0",
              }}
            >
              Welcome Back !
            </Typography>
            <LoadingButton
              loading={loader}
              variant="outlined"
              size="medium"
              style={{
                width: "100%",
                backgroundColor: "#270659",
                fontSize: 18,
                color: "white",
                textTransform: "capitalize",
              }}
              onClick={handleSSO}
            >
              Login Here
            </LoadingButton>
            {error && (
              <Typography
                variant="body2"
                style={{ color: "red", marginTop: "15px" }}
              >
                {error}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
