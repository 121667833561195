import * as React from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export default function Textarea(props) {
  return (
    <Box>
      {
        props.isLable ? (
          <Typography variant="label"
          style={{fontSize: '14px', textAlign:"left"}}>
            {props.lableValue}
          </Typography>
        ) : null
      }
 <TextareaAutosize
      aria-label="minimum height"
      minRows={5}
      placeholder="Type your comments here"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      style={{ width: '100%', padding: '5px', height: 125,borderColor:'#0000003b',borderRadius: '4px' }}
    />
    </Box>
   
  );
}