import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';

export default function SelectSmall(props) {
  return (
    <FormControl sx={{ minWidth: "100%" }} size="small" >
      {
        props.isLable ? (
          <Typography style={{ fontSize: '14px', fontWeight: '400' }} variant={props.lableVarient}>
            {props.lableValue}
          </Typography>
        ) : null
      }
      <Select
        style={{ margin: "4px 0" }}
        labelId="demo-select-small"
        id="demo-select-small"
        value={props.value}
        name={props.name}
        error={props.error}
        onChange={props.onChange}
        variant='outlined'
      >  
{props.options &&
  props.options.map((d) => {
    const key = props.datakey ? d[props.datakey] : d;
    const value = props.datakey && props.dataValue ? d[props.dataValue] : d;

    return (
      <MenuItem key={key} id={key} value={value}>
        {value}
      </MenuItem>
    );
  })
}
      </Select>
    </FormControl>
  );
}

