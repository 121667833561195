import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelIcon from "@mui/icons-material/Cancel";

const SuccessPopup = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      {props.popupError ? (
        <Dialog
          // fullScreen={fullScreen}
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <CancelIcon
              style={{ marginLeft: "40%", color: "red" }}
              fontSize="large"
            />
            <DialogContentText>
              <p style={{ color: "Red", fontWeight: "bold" }}>
                {" "}
                Unable to submit!!, Please try again
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={props.onClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          fullScreen={fullScreen}
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <TaskAltIcon
              style={{ marginLeft: "40%" }}
              fontSize="large"
              color="success"
            />
            <DialogContentText>
              <p style={{ color: "green", fontWeight: "bold" }}>
                {props.msg ? props.msg : "User Added Successfully"}
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={props.onClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default SuccessPopup;
