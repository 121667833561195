import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import configParam from "../Config";
import NewSample from "./NewSample";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  popupShow,
  CalcinedSection,
  MilledSection,
  DriedSection,
  SievedSection,
  FormedIntoSlurry,
  dryAged,
  newSampleSection,
  StaticFields,
  combinedSection,
  viewChange,
  fieldChange,
  psdFilesData,
  trialStatus,
  imageFilesData,
  slumpFilesData,
  rheologyFilesData,
  formedSlurryType1,
  formedSlurryType2,
  formedSlurryType3,
  selectSampleDisable,
} from "../recoil/atom";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import axios from "axios";
import * as ExcelJS from "exceljs";
const ApiURL = configParam.API_URL;

const CustomerTable = (props) => {
  const [open, setOpen] = useRecoilState(popupShow);
  const [, setInsertVisitData] = useRecoilState(trialStatus);
  const [, setResetSample] = useRecoilState(StaticFields);
  const [, setValue] = useRecoilState(fieldChange);
  const [, setSelectDisable] = useRecoilState(selectSampleDisable);
  const [isLoading, setIsLoading] = useState(false);

  const resetCalcDetValue = useResetRecoilState(CalcinedSection);
  const resetMilledDetValue = useResetRecoilState(MilledSection);
  const resetDriedDetValue = useResetRecoilState(DriedSection);
  const resetSievedDetValue = useResetRecoilState(SievedSection);
  const resetFormedDetValue = useResetRecoilState(FormedIntoSlurry);
  const resetFormedDetValue1 = useResetRecoilState(formedSlurryType1);
  const resetFormedDetValue2 = useResetRecoilState(formedSlurryType2);
  const resetFormedDetValue3 = useResetRecoilState(formedSlurryType3);
  const resetDryAgedDetValue = useResetRecoilState(dryAged);
  const resetNEwSampleDetValue = useResetRecoilState(newSampleSection);
  const resetCombinedDetValue = useResetRecoilState(combinedSection);
  const resetPsdDetValue = useResetRecoilState(psdFilesData);
  const resetImgDetValue = useResetRecoilState(imageFilesData);
  const resetSlumpDetValue = useResetRecoilState(slumpFilesData);
  const resetRheDetValue = useResetRecoilState(rheologyFilesData);
  const [, setViewVisit] = useRecoilState(viewChange);
  const userDetails = localStorage.getItem("loginUserDetails");
  const loginUserDetails = JSON.parse(userDetails);

  const createNewSampleNum = () => {
    let temObj = {
      sample_number: Math.floor(Date.now()).toString().substring(5),
      entry_date: dayjs(Date.now()),
      parent_id: "",
      oddity_flag: "",
      moisture_content: "",
      note_id: "",
      image_ids: "",
      primary_operator_id: "",
      additional_operator_ids: "",
      psd_ids: "",
      rheology_ids: "",
      slump_ids: "",
      ssa_ids: "",
      dsc_ids: "",
      operation_id: "",
      addNotes: "",
    };
    setResetSample(temObj);
  };
  const onHandleCreate = () => {
    setSelectDisable(false);
    createNewSampleNum();
    resetCalcDetValue();
    resetMilledDetValue();
    resetDriedDetValue();
    resetSievedDetValue();
    resetFormedDetValue();
    resetDryAgedDetValue();
    resetNEwSampleDetValue();
    resetFormedDetValue1();
    resetFormedDetValue2();
    resetFormedDetValue3();
    resetCombinedDetValue();
    resetPsdDetValue();
    resetImgDetValue();
    resetSlumpDetValue();
    resetRheDetValue();
    setOpen(true);
    setValue("Calcined");
    setViewVisit(false);
    setInsertVisitData(0);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const arrayQueries = [
    "gypsum.sample",
    "gypsum.milled",
    "gypsum.mill_media",
    "gypsum.mill_jar_type",
    "gypsum.dried",
    "gypsum.combined",
    "gypsum.calcined",
    "gypsum.sieved",
    "gypsum.new_arrival",
    "gypsum.dry_aged",
    "gypsum.formed_into_slurry",
    "gypsum.rheology_modifier",
    "gypsum.mix_equipment",
    "gypsum.retarder",
    "gypsum.notes",
    "gypsum.psd_measurement",
    "gypsum.rheology_measurement",
    "gypsum.images",
    "gypsum.slump",
    "gypsum.ssa",
    "gypsum.dsc",
    "gypsum.operators",
  ];

  const modifiedQueries = arrayQueries.map(
    (query) => `${query} WHERE isdelete = "false"`
  );
  const handleDownload = async () => {
    setIsLoading(true);
    const workbook = new ExcelJS.Workbook();
    try {
      for (let i = 0; i < arrayQueries.length; i++) {
        await processQuery(arrayQueries[i], workbook);
      }
      const date = new Date();
      const shortDate = date.toLocaleDateString();
      const filename = `Gypsum_data_${shortDate}.xlsx`;
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const processQuery = async (query, workbook) => {
    const [, table] = query.split(".");
    const response = await axios.get(ApiURL + "get_allDbdata", {
      params: { query },
      headers: {
        authorization: loginUserDetails.web_token
      },
    });

    if (response.status === 200) {
      if (response.data.data.res) {
        const worksheet = workbook.addWorksheet(table);
        if (response.data.data.res.length > 0) {
          const headers = Object.keys(response.data.data.res[0]);
          const headerRow = worksheet.addRow(headers);
          headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { horizontal: "center" };
          });

          headers.forEach((header, colIndex) => {
            const columnData = response.data.data.res.map(
              (rowData) => rowData[header]
            );
            const columnWidth = columnData.reduce((max, value) => {
              const valueLength = value ? value.toString().length : 0;
              return Math.max(max, valueLength);
            }, header.length);

            worksheet.getColumn(colIndex + 1).width = columnWidth + 2;
          });

          response.data.data.res.forEach((rowData) => {
            const rowValues = headers.map((header) => rowData[header]);
            worksheet.addRow(rowValues);
          });
        }
      } else {
        console.error("Response data is empty for table:", table);
      }
    } else {
      console.error("Request failed with status code:", response.status);
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        {isLoading && (
          <div className="loader">
            <CircularProgress />{" "}
          </div>
        )}
        <Button
          variant="contained"
          onClick={handleDownload}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 20,
            backgroundColor: "#270659",
          }}
        >
          <DownloadIcon style={{ cursor: "pointer" }} />
          <Typography style={{ textTransform: "capitalize" }}>
            <span className="text-hide">Download Excel</span>
          </Typography>
        </Button>

        <Button
          variant="contained"
          className="newsample-button"
          startIcon={<AddIcon />}
          style={{ cursor: "pointer", backgroundColor: "#270659" }}
          onClick={onHandleCreate}
        >
          <span className="text-hide">New Sample</span>{" "}
        </Button>
      </Grid>
      <NewSample open={open} handleClose={handleClose} />
    </React.Fragment>
  );
};

export default CustomerTable;
