import React, { useState, useEffect } from "react";
import TextBox from "../input/TextBox";
import { Grid, Typography, Card, CardContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton } from "@mui/material";
import { ReactComponent as Upload } from "../asset/fileUpload.svg";
import { ReactComponent as AddRow } from "../asset/AddRows.svg";
import { ssaFilesData, user } from "../recoil/atom";
import { useRecoilState } from "recoil";
import PsdDatePicker from "../input/PsdDatePicker";
import configParam from "../Config";
import { ReactComponent as DeleteRow } from "../asset/deleteIcon.svg";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
const ApiURL = configParam.API_URL;
const deepCopy = configParam.DEEP_COPY;

const SsaModal = (props) => {
  const [ssaData, setSsaData] = useRecoilState(ssaFilesData);
  const [popupMsg, setPopupMsg] = useState("");
  const [errors, setErrors] = useState(false);
  const userDetails = localStorage.getItem("loginUserDetails");
  const loginUserDetails = JSON.parse(userDetails);

  const handleSsaChange = (val, index, key) => {
    let tmpObj = deepCopy(ssaData);
    tmpObj[index][key] = val;
    setSsaData(tmpObj);
  };

  const tmpObj1 = [...ssaData];
  const obj1 = {
    id: "",
    imageDate: "",
    surface_area: "",
    file: {
      name: "",
      type: "",
      content: "",
      error: false,
      fileErr: false,
    },
  };
  const addRow = (index) => {
    if (ssaData[index].imageDate === "" || ssaData[index].surface_area === "") {
      setErrors(true);
    } else {
      tmpObj1.push(obj1);
      // tmpObj1.visitStatus = "new"
      setSsaData(tmpObj1);
      setErrors(false);
    }
  };
  const deleteRow = (index) => {
    let tmpObj = deepCopy(ssaData);
    tmpObj.splice(index, 1);
    setSsaData(tmpObj);
  };
  useEffect(() => {}, [ssaData]);

  function handleUpload(e, index) {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf";
    input.style.display = "none";
    document.body.appendChild(input);
    input.addEventListener("change", function (e) {
      handleFileChange(e, index);
    });
    input.click();
  }

  const handleFileChange = async (event, index) => {
    let tmpObj = deepCopy(ssaData);
    let uploadedFiles = event.target.files;
    let fileUpload = uploadedFiles[0];
    if (fileUpload.size > 10 * 1024 * 1024) {
      setPopupMsg("File size must be less than 10MB.");
      setTimeout(() => {
        setPopupMsg("");
      }, 4000);
      return;
    }

    let tmpFileObj = { name: fileUpload.name, type: fileUpload.type };
    let selectedFileName = tmpFileObj.name;
    let selectedType = tmpFileObj.type;

    if (fileUpload.type === "application/pdf") {
      const reader = new FileReader();
      reader.onload = () => {
        // The result property contains the Blob as a base64-encoded string
        const changePdf64 = reader.result;
        uploadBase64Pdf(changePdf64, selectedFileName, selectedType);
      };
      tmpObj[index]["file"] = tmpFileObj;
      setSsaData(tmpObj);
      reader.readAsDataURL(fileUpload);
    } else {
      setPopupMsg("Please Select PDF Files");
      setTimeout(() => {
        setPopupMsg("");
      }, 4000);
    }
  };

  async function uploadBase64Pdf(changePdf64, name, type) {
    const url = ApiURL + "upload";
    axios
      .post(url, {
        image: changePdf64
          .toString()
          .replace("data:application/pdf;base64,", ""),
        imageName: name.toString().replace(/ /g, "%20"),
        fileType: type,
        sampleModal:"ssa",
        headers: {
          authorization: loginUserDetails.web_token,
          //secret_key: loginUserDetails.web_secret
        },
      })
      .then((response) => {
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  }

  const downloadFile = (imageName) => {
    const cleanedImageName = imageName.replace(/\s/g, "");
    const urlName = ApiURL + "download/" + cleanedImageName;
    const link = document.createElement("a");
    link.href = urlName;
    link.download = imageName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (ssaData.length === 0) {
    tmpObj1.push(obj1);
    setSsaData(tmpObj1);
  }
  return (
    <React.Fragment>
      <Typography variant="body1">SSA Details</Typography>
      {ssaData.map((value, index) => {
        return (
          <Card
            sx={{
              minWidth: 275,
              boxShadow: "none",
              border: "0.5px solid rgb(224 224 224)",
            }}
            style={{ marginTop: 20 }}
          >
            <CardContent>
              <Grid container spacing={1}>
                <Grid item sm="6" xs="12">
                  <PsdDatePicker
                    isLable={true}
                    lableVarient="label"
                    lableValue="Date (month,day,year)"
                    name="imageDate"
                    value={value.imageDate}
                    onChange={(e) =>
                      handleSsaChange(e.target.value, index, "imageDate")
                    }
                    error={errors && !value.imageDate ? true : false}
                  />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox
                    isLable={true}
                    lableVarient="label"
                    lableValue="Surface Area"
                    name="surface_area"
                    value={value.surface_area}
                    onChange={(e) =>
                      handleSsaChange(e.target.value, index, "surface_area")
                    }
                    error={errors && !value.surface_area ? true : false}
                  />
                </Grid>
                <Grid item sm="6" xs="12">
                  <TextBox
                    isLable={true}
                    isString={true}
                    lableVarient="label"
                    lableValue="Attach Documents"
                    uploadImage={
                      <Upload onClick={(e) => handleUpload(e, index)} />
                    }
                    disabled={true}
                    value={value.file ? value.file.name : ""}
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Note: Only PDF files are allowed
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm="6"
                  xs="12"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  {value.file.name ? (
                    <div
                      style={{
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 20,
                      }}
                    >
                      <DownloadIcon
                        onClick={() => downloadFile(value.file.name)}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {ssaData.length - 1 === index && (
                    <div
                      role="button"
                      tabIndex="0"
                      style={{
                        cursor: "pointer",
                        display: "inline-flex",
                        borderRadius: "4px",
                        height: "40px",
                        alignItems: "center",
                        backgroundColor: "#270659",
                      }}
                      onClick={() => addRow(index)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          addRow(index);
                        }
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "15px",
                          fontSize: 14,
                          color: "#fff",
                        }}
                      >
                        Add Row
                      </div>
                      <AddRow fill="#fff" />
                    </div>
                  )}
                  {ssaData.length !== 1 && (
                    <Tooltip title="Delete Row" placement="top">
                      <div
                        style={{
                          cursor: "pointer",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 20,
                        }}
                      >
                        <DeleteRow onClick={() => deleteRow(index)} />
                      </div>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}
      {popupMsg && (
        <Alert
          style={{ width: "100%" }}
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setPopupMsg("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {popupMsg}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default SsaModal;
