import React from "react";
import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import RightContainer from "./RightContainer";
import TopAppBar from "./TopAppBar";
import { Link, Breadcrumbs } from "@mui/material";
import { useNavigate } from "react-router-dom";

function UserManagement() {
  const storedUserData = JSON.parse(localStorage.getItem("loginUserDetails"));
  let navigate = useNavigate("/visit");
  return (
    <React.Fragment>
      <Grid>
        <TopAppBar />
      </Grid>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ marginLeft: 27, marginTop: 10 }}
      >
        <Link
          color="inherit"
          onClick={() => navigate("/visit")}
          style={{
            cursor: "pointer",
            color: "#737373",
            fontWeight: "500",
            fontSize: "20px",
          }}
        >
          Home
        </Link>
        <Typography
          style={{
            cursor: "pointer",
            color: "#737373",
            fontWeight: "500",
            fontSize: "20px",
          }}
        >
          User Management
        </Typography>
      </Breadcrumbs>
      <Grid
        container
        spacing={1}
        columnSpacing={4}
        style={{
          marginLeft: "auto",
          width: "100%",
          border: "#f2f2f2",
          marginTop: "-25px",
        }}
      >
        <Grid item xl={4} sm={12} md={4} style={{ marginTop: " 8%" }}>
          <Card>
            <CardContent>
              <Avatar
                alt="Rimy Sharp"
                src="/broken-image.jpg"
                style={{
                  width: "250px",
                  height: "250px",
                  borderRadius: "50%",
                  margin: "auto",
                  textAlign: "center",
                  backgroundColor: "#1976d2",
                  fontSize: 26,
                }}
              >
                {storedUserData.name} <br />
              </Avatar>
              <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                Name
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 500, fontSize: 18 }}
              >
                {storedUserData.name} <br />
              </Typography>
              <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                Operator ID
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 500, fontSize: 18 }}
              >
                {storedUserData.operator_id}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xl={8} sm={12} md={8} style={{ marginTop: "5%" }}>
          <RightContainer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UserManagement;
