import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GetNewSampleData from '../components/GetNewSampleData';
import {  Grid } from '@mui/material';
import CustomerTable from './CustomerTable';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};



export default function LabTabs() {
  return (
    <Grid container style={{ padding: "16px" }}>
      <Grid item style={{ background: "#f1f1f1", padding: "16px", width: "100%" }}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <Typography variant="h5" style={{fontWeight: 'bold'}}>Submitted Samples</Typography>
          </Grid>
          <Grid item >
            <CustomerTable />
          </Grid>
        </Grid>
      <GetNewSampleData />
    </Grid>
      </Grid >
    
  );
}
