import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Addslump from '../components/Addslump';
import SsaModal from '../components/SsaModal';
import PsdModel from '../components/PsdModel';
import ImageModel from '../components/ImageModel';
import Rheology from '../components/Rheology';
import DscModel from '../components/DscModel';
import { tabActive } from '../recoil/atom';
import { useRecoilState } from 'recoil';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '16px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = useRecoilState(tabActive);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ bgcolor: '#efefef', height: 500, width: "100%", padding: '16px' }}
    >
      <Tabs
        orientation="harizontal"
        variant="scrollable"
        value={value}
        className='tabdesign'
        onChange={handleChange}
        aria-label="Vertical tabs example"
        // sx={{ borderRight: 1, borderColor: 'divider'}}
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Tab label="Add PSD" {...a11yProps(0)} className='tabtext' />
        <Tab label="Add IMAGE" {...a11yProps(1)} className='tabtext' />
        <Tab label="Add RHE" {...a11yProps(2)} className='tabtext' />
        <Tab label="Add SLUMP" {...a11yProps(3)} className='tabtext' />
        <Tab label="Add SSA" {...a11yProps(4)} className='tabtext' />
        <Tab label="Add DSC" {...a11yProps(5)} className='tabtext' />
        {/* <Tab label="" {...a11yProps(6)} /> */}
      </Tabs>
      <TabPanel className="whiteBG" value={value} index={0}>
        <PsdModel />
      </TabPanel>
      <TabPanel className="whiteBG" value={value} index={1}>
        <ImageModel />
      </TabPanel>
      <TabPanel className="whiteBG" value={value} index={2}>
        <Rheology />
      </TabPanel>
      <TabPanel className="whiteBG" value={value} index={3}>
        <Addslump />
      </TabPanel>
      <TabPanel className="whiteBG" value={value} index={4}>
        <SsaModal />
      </TabPanel>
      <TabPanel className="whiteBG" value={value} index={5}>
        <DscModel />
      </TabPanel>
    </Box>
  );
}