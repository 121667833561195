import React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import { FormControl } from '@mui/material';

const DateTimeMonth = (props) => {
  return (
    <div>
      {props.isLable ? (
        <Typography variant="label" style={{ fontSize: '14px' }}>
          {props.lableValue}
        </Typography>
      ) : null}

      <FormControl style={{ width: '100%', marginBottom: '16px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            style={{ width: '100%' }}
            disableFuture
            openTo="year"
            views={['year', 'month', 'day']}
            value={props.value}
            onChange={props.disabled ? null : props.onChange} // Prevent change when disabled
            disabled={props.disabled} // Disable DatePicker if required
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: '100%' }}
                size="small"
                error={props.error}
                disabled={props.disabled} // Disable TextField if required
                InputProps={{
                  endAdornment: null,
                }}
              />
            )}
          />
        </LocalizationProvider>
      </FormControl>
    </div>
  );
};

export default DateTimeMonth;
