import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelIcon from "@mui/icons-material/Cancel";

const SuccessPopup = (props) => {
 const theme = useTheme();
 const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
 const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
 return (
  <div>
  {props.popupError ? (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: isMobile ? '10px' : '20px' }}>
          <CancelIcon style={{ color: "red", fontSize: isMobile ? '3rem' : '4rem' }} />
        </div>
        <DialogContentText>
          <p style={{ color: "red", fontWeight: "bold", textAlign: 'center', fontSize: isMobile ? '1rem' : '1.25rem' }}>
            Unable to submit!!, Please try again
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: isMobile ? '10px' : '20px' }}>
          <TaskAltIcon style={{ color: theme.palette.success.main, fontSize: isMobile ? '3rem' : '4rem' }} />
        </div>
        <DialogContentText>
          <p style={{ color: "green", fontWeight: "bold", textAlign: 'center', fontSize: isMobile ? '1rem' : '1.25rem' }}>
            {props.msg ? props.msg : "Form successfully submitted"}
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )}
</div>
 );
};

export default SuccessPopup;
