import React from 'react';
import InputMask from 'react-input-mask';
import Box from '@mui/material/Box';
import { Typography,FormHelperText  } from '@mui/material';
const MaskInput = (props) => {
  const inputStyle = {
    height: '40px',
    width: '100%',
    borderRadius: 5,
    border: props.error ? '1px solid red' : '1px solid #ccc', // Set border color based on props.error
  };
  return (
    <Box component="form"
      style={{ width: "100%", marginBottom: '16px' }}
      noValidate
      autoComplete="off">
      {props.isLable ? (
        <Typography variant="label" style={{ fontSize: '14px', fontWeight: '400' }}>
          {props.lableValue}
        </Typography>
      ) : null}
      <InputMask mask={props.calender ? "99:99:99" : "999:99:99"} maskChar={null} value={props.value} onChange={props.onChange} error={props.error ? props.error : false} style={inputStyle}  placeholder="HHH:MM:SS"
      />
        {props.helperText && (
    <FormHelperText sx={{ color: props.helperText ? 'red' : 'inherit' }}>{props.helperText}</FormHelperText>
  )}
    </Box>
  );
}

export default MaskInput;
